import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { getUserInfo } from '../../actions/users/getUserInfo';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { List, Button, Image } from 'semantic-ui-react';

const WorkspaceListing = ({ items, isEditMode }) => {
  const { user } = useSelector((state) => ({
    user: state.userInfo.userInfo,
  }));

  const [userList, setUserList] = useState({});
  const dispatch = useDispatch();

  const typeIcons = {
    Page: 'file alternate outline',
    Image: 'image outline',
    File: 'file outline',
  };

  const actionType = (item) => {
    switch (true) {
      case item['ModificationDate'] < item['pretashare_last_comment_date']:
        return 'commented ';
      case item['ModificationDate'] === item['CreationDate']:
        return 'created ';
      case item['ModificationDate'] > item['CreationDate']:
        return 'modified ';
      default:
        return 'created ';
    }
  };

  const lastComment = (item) => {
    switch (true) {
      case item['ModificationDate'] < item['pretashare_last_comment_date']:
        return (
          <>
            <br />
            &quot;<em>{item['pretashare_latest_comment']}</em>&quot;
          </>
        );
      default:
        return;
    }
  };

  const lastActor = (item) => {
    switch (true) {
      case item['ModificationDate'] < item['pretashare_last_comment_date']:
        return item['pretashare_latest_commenter'];
      case item['ModificationDate'] === item['CreationDate']:
        return item['Creator'];
      case item['ModificationDate'] > item['CreationDate']:
        return item['pretashare_last_actor'];
      default:
        return item['Creator'];
    }
  };

  useEffect(() => {
    var users = {};
    items.map((value, key) => {
      const actor = lastActor(value);
      if (actor) {
        users[actor] = 'set';
      }
      return {
        ...items,
      };
    });
    if (users) {
      Object.keys(users).forEach(function (key) {
        dispatch(getUserInfo(key));
      });
    }
  }, [dispatch, items]);

  useEffect(() => {
    if (user.id) {
      var newuser = {};
      newuser[user.id] = user.portrait;
      setUserList((prev) => {
        return { ...prev, ...newuser };
      });
    }
  }, [user]);

  return (
    <>
      <div>
        <List
          animated
          divided
          relaxed
          size="large"
          style={{ marginTop: 2 + 'em' }}
        >
          {items.map((item, index) => {
            return (
              <List.Item
                key={item['@id']}
                style={{ paddingTop: 1.5 + 'em', marginBottom: 0.5 + 'em' }}
              >
                <List.Icon
                  name={
                    typeIcons[item.Type] ? typeIcons[item.Type] : 'file outline'
                  }
                  size="huge"
                  verticalAlign="middle"
                  style={{ marginRight: 0, paddingRight: 0 }}
                />
                <List.Content>
                  <List.Header>
                    <UniversalLink
                      href={isEditMode ? '#' : flattenToAppURL(item['@id'])}
                    >
                      {item.title ? item.title : item.id}
                    </UniversalLink>
                  </List.Header>
                  <List.Content>
                    <List.List>
                      <List.Item>
                        {userList[lastActor(item)] ? (
                            <Image
                            avatar
                            style={{ width: 'auto', height: '2em' }}
                            src={userList[lastActor(item)]}
                          />
                        ): null}
                        <List.Content>
                          <List.Description>
                            <p>
                              <strong>{lastActor(item)}</strong>{' '}
                              {actionType(item)}{' '}
                              {moment(
                                item['pretashare_last_activity'],
                              ).fromNow()}
                              {lastComment(item)}
                            </p>
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List.List>
                  </List.Content>
                  {!items[index]?.Subject?.length ? null : (
                    <div>
                      {item.Subject.map((subject) => {
                        return (
                          <Button
                            key={subject}
                            size="mini"
                            href={
                              isEditMode ? null : '/search?Subject=' + subject
                            }
                          >
                            {subject}
                          </Button>
                        );
                      })}
                    </div>
                  )}
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    </>
  );
};

WorkspaceListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default WorkspaceListing;
