import getListingBlockAsyncData from '@plone/volto/components/manage/Blocks/Listing/getAsyncData';
import {
  CardListing,
  LibraryListing,
  ReportListing,
  WorkspaceDashboard,
  WorkspaceListing,
  WorkspaceView,
} from './components';
import FileView from './customizations/volto/components/theme/View/FileView.jsx';
// All your imports required for the config here BEFORE this line
import { getUser } from '@plone/volto/actions';
import '@plone/volto/config';
import jwtDecode from 'jwt-decode';

import avatarMiddleware from './express-middleware/avatar';
import portraitMiddleware from './express-middleware/portrait';
import sitelogoMiddleware from './express-middleware/sitelogo';
import uploadMiddleware from './express-middleware/upload';
import { getBaseUrl } from '@plone/volto/helpers';
import { getSiteInfo } from './actions'

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.views = {
    ...config.views,
    contentTypesViews: {
      File: FileView,
      Video: FileView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      library_listing: LibraryListing,
      workspaces_view: WorkspaceView,
      workspace_view: WorkspaceView,
      leftnav_view: WorkspaceView,
    },
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    getAsyncData: ({ dispatch, data, path }) => {
      // Slower performance as we need the full object, but
      //   we use this to get the preview_image.
      data.querystring.fullobjects = true;
      return getListingBlockAsyncData({ dispatch, data, path });
    },
  };

  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'cardListing',
      title: 'Card Listing',
      template: CardListing,
    },
    {
      id: 'reportListing',
      title: 'Report Listing',
      template: ReportListing,
    },
    {
      id: 'workspaceDashboard',
      title: 'Workspace Dashboard',
      template: WorkspaceDashboard,
      restricted: 'Plone Site',
    },
    {
      id: 'workspaceListing',
      title: 'Workspace Listing',
      template: WorkspaceListing,
      restricted: 'Workspace',
    },
  ];

  config.settings.asyncPropsExtenders = [
    ...(config.settings.asyncPropsExtenders || []),
    {
      path: '/',
      extend: (dispatchActions) => {
        const siteInfo = {
          key: 'siteInfo',
          promise: ({ location, store }) => {
            const action = getSiteInfo();
            return store.dispatch(action).catch((e) => {
              // eslint-disable-next-line
              console.log(
                "Error getting siteinfo",
              );
            });
          },
        };
        const userInfo = {
          key: 'userInfo',
          promise: ({ location, store }) => {
            const state = store.getState();
            const action = getUser(
              state.userSession.token
                ? jwtDecode(state.userSession.token).sub
                : '',
            );
            return store.dispatch(action).catch((e) => {
              // eslint-disable-next-line
              console.log(
                "Error getting user info",
              );
            });
          },
        }
        return [...dispatchActions, siteInfo, userInfo];
      },
    },
  ];

  if (__SERVER__) {
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware,
      avatarMiddleware(),
      portraitMiddleware(),
      sitelogoMiddleware(),
      uploadMiddleware(),
    ];
  }

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    '/admin',
  ];

  return config;
}
