/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Form, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { postRequestOTP, postSendOTP } from '../../actions';
import { getBaseUrl } from '@plone/volto/helpers';
import { Toast } from '@plone/volto/components';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import qs from 'query-string';

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

class EmailLoginComponent extends Component {
  static propTypes = {
    postRequestOTP: PropTypes.func.isRequired,
    postSendOTP: PropTypes.func.isRequired,
  };

  static defaultProps = {
    requestOTP: null,
    submitOTP: null,
    token: null,
    returnUrl: null,
    code: null,
    otp: null,
  };

  constructor(props) {
    super(props);
    this.submitEmail = this.submitEmail.bind(this);
    this.submitOTP = this.submitOTP.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.state = {
      showEmailEntry: true,
      emailFormLoading: false,
      showMaskedEmail: false,
      showOTPEntry: false,
      otpFormLoading: false,
      email: null,
      emailError: null,
      otpError: null,
      returnUrl: '',
    };
  }

  componentDidMount() {
    // If a code is submitted, we send that instead
    if (this.props.code) {
      this.setState({
        emailFormLoading: true,
      });
      // If we have an OTP in the request we attempt to log in
      if (this.props.otp) {
        this.props.postSendOTP(
          null,
          null,
          this.props.code,
          this.props.otp,
          this.props.returnUrl,
        );
      } else {
        this.props.postRequestOTP(null, this.props.code, this.props.returnUrl);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let login = false;
    if (nextProps.token) {
      login = true;
      sleep(2000).then(() => {
        this.props.history.push(getBaseUrl(this.props.returnUrl));
      });
    }
    if (this.props.requestOTP.loading) {
      if (nextProps.requestOTP.loaded) {
        this.onSubmitEmailSuccess(nextProps.requestOTP);
      } else if (nextProps.requestOTP.error) {
        this.onSubmitEmailError();
      }
    }
    if (this.props.submitOTP.loading && login === false) {
      // This catches failure (success would lead to redirect)
      sleep(500).then(() => {
        this.onSubmitOTPError();
      });
    }
  }

  submitEmail(event) {
    const data = Object.fromEntries(new FormData(event.target));
    if (!isValidEmail(data.email)) {
      this.setState({
        emailError: {
          content: 'Please enter a valid email address',
          pointing: 'below',
        },
      });
      return;
    }
    this.setState({
      emailFormLoading: true,
    });
    this.props.postRequestOTP(data.email, null, this.props.returnUrl);
    this.setState({
      email: data.email,
    });
  }
  onSubmitEmailSuccess(data) {
    let showMaskedEmail = false;
    if (this.props.code) {
      showMaskedEmail = true;
    }
    this.setState({
      showEmailEntry: false,
      showOTPEntry: true,
      showMaskedEmail: showMaskedEmail,
      maskedEmail: data.email,
    });
  }
  onSubmitEmailError() {
    this.setState({
      emailFormLoading: false,
    });
    toast.error(
      <Toast
        error
        title="Error"
        content="There was a problem logging in. Please try again."
      />,
    );
  }

  submitOTP(event) {
    const data = Object.fromEntries(new FormData(event.target));
    this.setState({
      otpError: null,
    });
    this.props.postSendOTP(data.code, this.state.email, this.props.code);
    this.setState({
      otpFormLoading: true,
    });
  }
  onSubmitOTPError() {
    const error = this.props.submitOTP.error || '';
    if (error.type === 'expired_credentials') {
      this.setState({
        otpFormLoading: false,
        emailFormLoading: false,
        emailError: {
          content: (
            <>
              The login code has expired.
              <br />
              Please re-enter your email address and try again.
            </>
          ),
          pointing: 'below',
        },
        showEmailEntry: true,
        showOTPEntry: false,
      });
      return;
    } else if (error.type === 'invalid_credentials') {
      this.setState({
        otpFormLoading: false,
        emailFormLoading: false,
        otpError: {
          content: (
            <>
              The code submitted was not valid.
              <br />
              Please check and try again
            </>
          ),
          pointing: 'below',
        },
      });
      return;
    }
    // Catch all for errors, go back to email entry
    this.setState({
      otpFormLoading: false,
      emailFormLoading: false,
      emailError: {
        content: (
          <>
            There was an error logging in.
            <br />
            Please re-enter your email address and try again.
          </>
        ),
        pointing: 'below',
      },
      showEmailEntry: true,
      showOTPEntry: false,
    });
  }

  changeEmail(event) {
    this.setState({
      otpFormLoading: false,
      emailFormLoading: false,
      showEmailEntry: true,
      showOTPEntry: false,
      showMaskedEmail: false,
    });
  }

  render() {
    return (
      <Container text>
        <Segment.Group raised>
          <Segment className="primary">
            <h1>Log In</h1>
          </Segment>
          <Segment secondary>
            {this.state.showEmailEntry ? (
              <>
                <p>To login to Vaultmatix enter your email address below.</p>
                <p>
                  If it is registered in our system you will receive an email
                  with a one-time code for logging in.
                </p>
                <Form
                  loading={this.state.emailFormLoading}
                  onSubmit={this.submitEmail}
                >
                  <Form.Input
                    name="email"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    label="Enter Email Address"
                    error={this.state.emailError}
                  />
                  <Form.Button>Send Code</Form.Button>
                </Form>
              </>
            ) : null}
            {this.state.showOTPEntry ? (
              <>
                <p>Enter the 6 digit code sent to your email:</p>
                {this.state.showMaskedEmail ? (
                  <>
                    <p>
                      Email was sent to{' '}
                      <strong>{this.state.maskedEmail}</strong>
                    </p>
                  </>
                ) : null}
                <Container textAlign="right">
                  <a href="#" onClick={this.changeEmail} aria-hidden="true">
                    Resend code
                  </a>
                </Container>
                <Form
                  loading={this.state.otpFormLoading}
                  onSubmit={this.submitOTP}
                >
                  <Form.Input
                    name="code"
                    label="Enter Code"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    error={this.state.otpError}
                  />
                  <Form.Button>Login</Form.Button>
                </Form>
              </>
            ) : null}
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      requestOTP: state.requestOTP,
      submitOTP: state.userSession.login,
      token: state.userSession.token,
      returnUrl:
        qs.parse(props.location.search).return_url ||
        props.location.pathname
          .replace(/\/login\/?.*/, '')
          .replace(/\/logout$/, '') ||
        '/',
      code: props.match.params.code,
      otp: props.match.params.otp,
    }),
    { postRequestOTP, postSendOTP },
  ),
)(EmailLoginComponent);
