/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
export const POST_REQUEST_OTP = 'POST_REQUEST_OTP';
export const POST_SEND_OTP = 'POST_SEND_OTP';
export const POST_SHARES = 'POST_SHARES';
export const GET_SHARES = 'GET_SHARES';
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const POST_ADD_OWNER = 'POST_ADD_OWNER';
export const GET_USERINFO = 'GET_USERINFO';
export const GET_SEARCHUSERS = 'GET_SEARCHUSERS';
export const GET_MIMETYPE = 'GET_MIMETYPE';
export const GET_SITEINFO = 'GET_SITEINFO';
export const POST_SITEINFO = 'POST_SITEINFO';
