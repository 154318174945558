import { GET_MIMETYPE } from '../../constants/ActionTypes';

const initialState = {
  name: null,
  error: null,
  loaded: false,
  loading: false,
};

export default function getMimeType(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_MIMETYPE}_PENDING`:
      return {
        ...state,
        name: null,
        loading: true,
        loaded: false,
        error: null,
      };
    case `${GET_MIMETYPE}_SUCCESS`:
      return {
        ...state,
        name: action.result.name,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_MIMETYPE}_FAIL`:
      return {
        ...state,
        name: null,
        loading: false,
        loaded: false,
        error: action.error.response.error,
      };
    default:
      return state;
  }
}
