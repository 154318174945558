/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoPloneReloader from 'volto-plone-reloader';
import ploneCollectivevoltoSentry from '@plone-collective/volto-sentry';

const addonsInfo = [{"modulePath":"/build/app/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/build/app/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.22.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"volto-plone-reloader","version":"0.1.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/app/node_modules/volto-plone-reloader/src","packageJson":"/build/app/node_modules/volto-plone-reloader/package.json","addons":[]},{"name":"@plone-collective/volto-sentry","version":"0.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/app/node_modules/@plone-collective/volto-sentry/src","packageJson":"/build/app/node_modules/@plone-collective/volto-sentry/package.json","addons":[],"razzleExtender":"/build/app/node_modules/@plone-collective/volto-sentry/razzle.extend.js"}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoPloneReloader, ploneCollectivevoltoSentry];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
