import { POST_REQUEST_OTP } from '../../constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  status: null,
  email: null,
};

export default function requestOTP(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_REQUEST_OTP}_PENDING`:
      return {
        ...state,
        status: null,
        loading: true,
        loaded: false,
        error: null,
        email: null,
      };
    case `${POST_REQUEST_OTP}_SUCCESS`:
      return {
        ...state,
        status: action.result.status,
        loading: false,
        loaded: true,
        error: null,
        email: action.result.email,
      };
    case `${POST_REQUEST_OTP}_FAIL`:
      return {
        ...state,
        status: 'Error',
        loading: false,
        loaded: false,
        error: action.error.response.error,
        email: null,
      };
    default:
      return state;
  }
}
