import { POST_REQUEST_OTP } from '../../constants/ActionTypes';

export function postRequestOTP(email, code, return_url) {
  return {
    type: POST_REQUEST_OTP,
    request: {
      op: 'post',
      path: `/@requestOTP`,
      data: {
        email: email,
        code: code,
        return_url: return_url,
      },
    },
  };
}
