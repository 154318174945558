import { GET_USERINFO } from '../../constants/ActionTypes';

export function getUserInfo(id) {
  return {
    type: GET_USERINFO,
    request: {
      op: 'get',
      path: `/@users/${id}`,
    },
  };
}