import React, { Component } from 'react';

import { ContentsUploadModal } from '@plone/volto/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getBaseUrl } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class WorkspaceUpload extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    objectPath: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.objectPath = this.props.pathname.replace(/(.*)\/@@upload$/, '$1');
    this.state = {
      showUpload: true,
    };
  }
  componentDidMount() {}

  /**
   * On upload close
   * @method onUploadClose
   * @returns {undefined}
   */

  onUploadClose(reset) {
    this.props.history.push(this.objectPath);
  }

  render() {
    return (
      <>
        {!__SERVER__ ? (
          <ContentsUploadModal
            open={this.state.showUpload}
            onClose={(refresh) => {
              this.onUploadClose(refresh);
            }}
            uploadURL={getBaseUrl(this.objectPath)}
          />
        ) : null}
      </>
    );
  }
}

export default compose(
  withRouter,
  connect((state, props) => ({
    pathname: props.location.pathname,
  })),
)(WorkspaceUpload);
