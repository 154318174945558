import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { deleteWorkspace } from '~/actions';
import { compose } from 'redux';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Toast } from '@plone/volto/components';
import clearSVG from '@plone/volto/icons/clear.svg';
import deleteSVG from '@plone/volto/icons/delete.svg';
import { Icon } from '@plone/volto/components';

const DeleteWorkspace = ({ toggleDeleteWorkspace, workspace, toastify }) => {
  const dispatch = useDispatch();
  // TODO: This should be reduxAsyncConnect to support SSR
  const { deleted, deleteError } = useSelector((state) => ({
    deleted: state.deleteWorkspace.delete?.loaded || false,
    deleteError: state.deleteWorkspace.delete?.error || null,
  }));

  const [callDeleteWorkspace, setDeleteWorkspace] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (deleted === true && callDeleteWorkspace === true) {
      setDeleteWorkspace(false);
      history.push(flattenToAppURL('/'));
      toastify.toast.success(
        <Toast success title="Workspace Deleted" content="" />,
      );
    }
  }, [deleted, history, dispatch, callDeleteWorkspace, toastify]);

  useEffect(() => {
    if (deleteError && callDeleteWorkspace === true) {
      setDeleteWorkspace(false);
      toastify.toast.error(
        <Toast
          success
          title="Error Deleting Workspace:"
          content={
            <>
              <strong>{deleteError.response.statusCode}</strong>:{' '}
              {deleteError.response.statusText}
            </>
          }
        />,
      );
    }
  }, [deleteError, callDeleteWorkspace, dispatch, toastify]);

  const handleDeleteWorkspace = (data) => {
    setDeleteWorkspace(true);
    dispatch(deleteWorkspace(flattenToAppURL(workspace)));
  };

  return (
    <Modal defaultOpen={true} closeOnDimmerClick={true} closeOnEscape={true}>
      <Modal.Header>Delete Workspace</Modal.Header>
      <Modal.Content>
        Are you sure you wish to delete this workspace and all of its content?
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={(e) => {
            toggleDeleteWorkspace();
          }}
          size="tiny"
        >
          <Icon name={clearSVG} size="20px" title="Cancel" />
          <span
            style={{
              position: 'relative',
              bottom: '5px',
            }}
          >
            Cancel
          </span>
        </Button>
        <Button
          onClick={(e) => {
            handleDeleteWorkspace();
          }}
          size="tiny"
        >
          <Icon name={deleteSVG} size="20px" title="Delete" />
          <span
            style={{
              position: 'relative',
              bottom: '5px',
            }}
          >
            Delete Workspace
          </span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  connect((store, props) => ({
    toastify: props.toastify,
  })),
  injectLazyLibs(['toastify']),
)(DeleteWorkspace);
