import { UniversalLink } from '@plone/volto/components';
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';

import {
  Api,
  getBaseUrl,
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  hasBlocksData,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

const Sidenav = ({ pathname }) => {
  const [items, setItems] = React.useState([]);

  const fetchData = useCallback(() => {
    const api = new Api();
    api.get(`${pathname}/contextual_navigation`).then((response) => {
      setItems(response);
    });
  }, [pathname]);

  useEffect(() => {
    fetchData(pathname);
  }, [fetchData, pathname]);

  let topDepth = 0;
  const [, , workspaceId] = pathname.split('/');

  const itemsWithLevels = items
    .filter(({ url, siblingContent }) => {
      if (
        url.includes(workspaceId) ||
        siblingContent === true ||
        pathname.includes('/library')
      ) {
        return true;
      }
      return false;
    })
    .map((item) => {
      const depth = item.siblingContent;
      topDepth = depth < topDepth ? depth : topDepth;

      return {
        ...item,
        depth: depth - topDepth,
        current: item.url === pathname,
      };
    });

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <Segment as="nav" className="sidenavWidget">
      <Header as="h3" size="large">
        {pathname.includes('/library') ? 'Library' : 'Workspace'}
      </Header>
      <List className="sidenavWidget">
        {itemsWithLevels.map(({ url, title, depth, current }) => {
          if (current) {
            return (
              <List.Item className={`level-${depth}`} key={url}>
                <List.Header>{title}</List.Header>
              </List.Item>
            );
          }
          return (
            <List.Item className={`level-${depth}`} key={url}>
              <UniversalLink href={url}>{title}</UniversalLink>
            </List.Item>
          );
        })}
      </List>
    </Segment>
  );
};

const WorkspaceView = ({ content, location }) => {
  const intl = useIntl();
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const TitleBlock = config.blocks.blocksConfig['title']?.['view'] || null;
  // const titleBlockId = content[blocksLayoutFieldname]?.items?[0];
  // debugger;

  return (
    <Container id="page-document">
      <Grid>
        <TitleBlock
          key={0}
          id={0}
          properties={content}
          data={content[blocksFieldname][0]}
          path={getBaseUrl(location?.pathname || '')}
        />
        <Grid.Row>
          <Grid.Column width={4}>
            <Sidenav pathname={location?.pathname} />
          </Grid.Column>
          <Grid.Column width={8}>
            {hasBlocksData(content) ? (
              map(content[blocksLayoutFieldname]?.items, (block, index) => {
                // Don't re-render the title.
                if (index === 0) {
                  return null;
                }
                const Block =
                  config.blocks.blocksConfig[
                    content[blocksFieldname]?.[block]?.['@type']
                  ]?.['view'] || null;
                return Block !== null ? (
                  <Block
                    key={block}
                    id={block}
                    properties={content}
                    data={content[blocksFieldname][block]}
                    path={getBaseUrl(location?.pathname || '')}
                  />
                ) : (
                  <div key={block}>
                    {intl.formatMessage(messages.unknownBlock, {
                      block: content[blocksFieldname]?.[block]?.['@type'],
                    })}
                  </div>
                );
              })
            ) : (
              <>
                <h1 className="documentFirstHeading">{content.title}</h1>
                {content.description && (
                  <p className="documentDescription">{content.description}</p>
                )}
                {/* {content.image && (
              <Image
                className="document-image"
                src={content.image.scales.thumb.download}
                floated="right"
              />
            )} */}
                {content.remoteUrl && (
                  <span>
                    The link address is:
                    <a href={content.remoteUrl}>{content.remoteUrl}</a>
                  </span>
                )}
                {content.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.text.data,
                    }}
                  />
                )}
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

WorkspaceView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default WorkspaceView;
