/**
 * Content reducer.
 * @module reducers/content/content
 */

import { GET_WORKSPACES, POST_ADD_OWNER } from '~/constants/ActionTypes';

const initialState = {
  create: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  content: null,
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Get workspaces reducer.
 */
export function getWorkspaces(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_WORKSPACES}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_WORKSPACES}_SUCCESS`:
      return {
        ...state,
        items: action.result.items,
        prev: action.result.batching?.prev,
        next: action.result.batching?.next,
        first: action.result.batching?.first,
        last: action.result.batching?.last,
        items_total: action.result.items_total,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_WORKSPACES}_FAIL`:
      return {
        ...state,
        items: [],
        items_total: null,
        permissions: {},
        next: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

/**
 * addOwnerForWorkspace reducer.
 */
export function addOwnerForWorkspace(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_ADD_OWNER}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${POST_ADD_OWNER}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${POST_ADD_OWNER}_FAIL`:
      const error = action.error?.response.body?.error
        ? action.error?.response.body?.error
        : action.error;
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: error,
        },
      };
    case `${POST_ADD_OWNER}_RESET`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: null,
        },
      };
    default:
      return state;
  }
}
