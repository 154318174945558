/**
 * @module components/theme/Unauthorized/Unauthorized
 */

import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';
import jwtDecode from 'jwt-decode';

/**
 * unauthorized function.
 * @function Unauthorized
 * @returns {string} Markup of the unauthorized page.
 */
const Unauthorized = () => {
  const { userId, error_message } = useSelector((state) => ({
    userId: state.userSession.token
      ? jwtDecode(state.userSession.token).sub
      : '',
    error_message: state.apierror.message,
  }));
  let location = useLocation();
  const history = useHistory();
  if (userId === '') {
    history.push(getBaseUrl(location.pathname) + '/login');
  }

  return userId === '' ? null : (
    <Container className="view-wrapper">
      <h1>
        <FormattedMessage id="Unauthorized" defaultMessage="Unauthorized" />
      </h1>
      <h3>{error_message}</h3>
      <p>
        <FormattedMessage
          id="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
          defaultMessage="If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}."
          values={{
            site_admin: (
              <Link to="/contact-form">
                <FormattedMessage
                  id="Site Administration"
                  defaultMessage="Site Administration"
                />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="Thank you." defaultMessage="Thank you." />
      </p>
    </Container>
  );
};
export default Unauthorized;
