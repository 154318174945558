import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { ModalForm } from '@plone/volto/components';
import { createWorkspace } from '~/actions';
import { compose } from 'redux';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Toast } from '@plone/volto/components';

const AddWorkspace = ({ toggleWorkspace, toastify }) => {
  const dispatch = useDispatch();
  // TODO: This should be reduxAsyncConnect to support SSR
  const { created, content, createError } = useSelector((state) => ({
    created: state.createWorkspace.create?.loaded || false,
    content: state.createWorkspace.content || null,
    createError: state.createWorkspace.create?.error || null,
  }));

  const [callCreateWorkspace, setCreateWorkspace] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (created === true && callCreateWorkspace === true) {
      setCreateWorkspace(false);
      history.push(flattenToAppURL(content['@id']));
      toastify.toast.success(
        <Toast success title="Workspace Created" content="" />,
      );
    }
  }, [
    created,
    setCreateWorkspace,
    dispatch,
    history,
    callCreateWorkspace,
    content,
    toastify,
  ]);

  useEffect(() => {
    if (createError) {
      toastify.toast.error(
        <Toast
          success
          title="Error Creating Workspace:"
          content={
            <>
              <p>
                <strong>{createError.response.statusCode}</strong>:{' '}
                {createError.response.statusText}
              </p>
            </>
          }
        />,
      );
    }
  }, [createError, dispatch, toastify]);

  const handleAddWorkspace = (data) => {
    setCreateWorkspace(true);
    dispatch(
      createWorkspace('/', {
        '@type': 'Workspace',
        title: data.title,
        description: data?.description || null,
      }),
    );
  };

  return (
    <ModalForm
      title="Add Workspace"
      onCancel={() => toggleWorkspace()}
      onSubmit={(data) => {
        handleAddWorkspace(data);
      }}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: 'Add Workspace',
            fields: ['title', 'description'],
          },
        ],
        properties: {
          title: {
            title: 'Workspace Title',
            type: 'string',
            description: 'Enter a workspace title',
          },
          description: {
            title: 'Description',
            type: 'string',
            description: 'Enter a description for the workspace',
            widget: 'textarea',
          },
        },
        required: ['title'],
      }}
    />
  );
};

export default compose(
  connect((store, props) => ({
    toastify: props.toastify,
  })),
  injectLazyLibs(['toastify']),
)(AddWorkspace);
