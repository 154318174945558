/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import filesize from 'filesize';
import { useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { getMimeType } from '~/actions';

/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const FileView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  const dispatch = useDispatch();
  // TODO: This should be reduxAsyncConnect to support SSR
  const { mimeType } = useSelector((state) => ({
    mimeType: state.getMimeType.name,
  }));

  useEffect(() => {
    if (content.file['content-type'] !== null) {
      dispatch(getMimeType(content.file['content-type']));
    }
  }, [content, dispatch]);

  return (
    <Container className="view-wrapper">
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && ` - ${content.subtitle}`}
      </h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.file?.download && (
        <a href={flattenToAppURL(content.file.download)}>
          {`Download ${content.file.filename} (${mimeType}, ${filesize(
            content.file.size,
          )})`}
        </a>
      )}

      {content['@type'] === 'Video' ? (
        <Segment>
          <video
            style={{ width: '100%' }}
            src={content.file.download}
            controls
            type="video/mp4"
          >
            <track />
          </video>
        </Segment>
      ) : null}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FileView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
      'content-type': PropTypes.string,
    }),
  }).isRequired,
};

export default FileView;
