/**
 * Admin component.
 * @module components/Admin/Admin
 */

import { getUser, getSharing } from '@plone/volto/actions';
import { Form, Icon, Toolbar } from '@plone/volto/components';
import {
  flattenToAppURL,
  getBaseUrl
} from '@plone/volto/helpers';

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Container, Tab, Segment } from 'semantic-ui-react';
import UserManagement from './UserManagement';
import { getSiteInfo, setSiteInfo } from '@root/actions';
import WorkspaceManagement from './WorkspaceManagement';
import { defineMessages, injectIntl } from 'react-intl';

import backSVG from '@plone/volto/icons/back.svg';
import configurationSVG from '@plone/volto/icons/configuration.svg';
import groupSVG from '@plone/volto/icons/group.svg';
import registrySVG from '@plone/volto/icons/registry.svg';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  manageWorkspaces: {
    id: 'Manage Workspaces',
    defaultMessage: 'Manage Workspaces',
  },
});

/*
 * Header component class.
 * @class Header
 * @extends Component
 */
class Admin extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      fullname: PropTypes.string,
      email: PropTypes.string,
      home_page: PropTypes.string,
      location: PropTypes.string,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    getUser: PropTypes.func.isRequired,
    getSharing: PropTypes.func.isRequired,
    getSiteInfo: PropTypes.func.isRequired,
    setSiteInfo: PropTypes.func.isRequired,
    sharing: PropTypes.array,
    siteInfo: PropTypes.object,
    siteInfoUpdatePost: PropTypes.object,
  };

  static defaultProps = {
    id: 'admin-controlpanel',
    sharing: [],
  };

  state = {
    isClient: false,
    loading: false,
  };

  componentDidMount() {
    this.props.getUser(this.props.userId);
    this.props.getSharing(flattenToAppURL('/'), this.props.userId);
    this.setState({ isClient: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.siteInfo.post.loading
      && nextProps.siteInfo.post.loaded){
        this.setState({loading: false});
        this.props.getSiteInfo(getBaseUrl('/'));
    }
  }

  checkPermission() {
    const userObj = this.props.sharing.filter(
      (e) => e.id === this.props.userId,
    );
    if (userObj.length) {
      const user = userObj[0];
      return user.roles['Share Workspace'];
    }
    return false;
  }

  submitSiteInfo(formData) {
    this.setState({loading: true});
    this.props.setSiteInfo(formData);
  }

  render() {
    const logo = {
      logo_url: flattenToAppURL(this.props.siteInfo.result.logo_url),
      logo_filename: this.props.siteInfo.result.logo_filename,
      logo_type: this.props.siteInfo.result.logo_type,
    }
    if (logo.logo_url
        && logo.logo_url.startsWith('/++resource++plone-logo.svg')) {
      logo.logo_url = '';
      logo.logo_filename = '';
      logo.logo_type = '';
    }

    const panes = [
      {
        menuItem: {
          key: 'users',
          content: (
            <>
              <Icon
                name={groupSVG}
                size="20px"
                className="unlock"
                title="Manage Users"
              />
              Manage Users
            </>
          ),
        },
        render: () => (
          <Tab.Pane>
            <Segment attached compact>
              <UserManagement />
            </Segment>
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: 'settings',
          content: (
            <>
              <Icon
                name={configurationSVG}
                size="20px"
                className="unlock"
                title="Site Settings"
              />
                Site Settings
            </>
          ),
        },
        render: () => (
          <Tab.Pane>
            {/* TODO: Move this to a css file */}
            <style
              dangerouslySetInnerHTML={{
                __html: `
                  .ui.bottom.attached.segment.active.tab:has(.ui.raised.segments) {
                    padding: 0;
                    }
                    .ui.bottom.attached.segment.active.tab .ui.raised.segments {
                      box-shadow: none;
                      border: 0;
                    }
                `,
              }}
            />
            <Form
              onSubmit={(formData) => {
                this.submitSiteInfo(formData);
              }}
              loading={this.state.loading}
              formData={{
                title: this.props.siteInfo.result.title,
                logo: {
                  download:logo.logo_url,
                  'content-type': logo.logo_type,
                  filename: logo.logo_filename,
                }
              }}
              schema={{
                fieldsets: [
                  {
                    id: 'default',
                    title: 'FIXME: User Data',
                    fields: ['title', 'logo'],
                  },
                ],
                properties: {
                  title: {
                    title: 'Site title',
                    type: 'string',
                  },
                  logo: {
                    title: 'Site logo',
                    widget: 'file',
                  },
                },
                required: [],
              }}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: {
          key: 'workspaces',
          content: (
            <>
              {/* TODO: Upgrade Volto to add the `ariaHidden` support. */}
              <Icon name={registrySVG} size="20px" ariaHidden={true} />
              {this.props.intl.formatMessage(messages.manageWorkspaces)}
            </>
          ),
        },
        render: () => (
          <Tab.Pane>
            <Segment attached compact>
              <WorkspaceManagement />
            </Segment>
          </Tab.Pane>
        ),
      },
    ];

    if (this.checkPermission()) {
      return (
        <Container id="admin" className="view-wrapper">
          <Tab panes={panes} />
          {this.state.isClient && (
            <Portal node={document.getElementById('toolbar')}>
              <Toolbar
                pathname={flattenToAppURL('/')}
                hideDefaultViewButtons
                inner={
                  <button onClick={this.props.history.goBack} className="item">
                    <Icon
                      name={backSVG}
                      className="contents circled"
                      size="30px"
                      title={this.props.intl.formatMessage(messages.back)}
                    />
                  </button>
                }
              />
            </Portal>
          )}
        </Container>
      );
    }

    // TODO: Redirect to unauthorized page/ 404 page if we're trying to avoid showing what pages exist and which don't.
    return <div />;
  }
}

export default compose(
  injectIntl,
  withRouter,
  connect(
    (state, props) => ({
      siteInfo: state.siteInfo,
      user: state.users.user,
      pathname: props.location.pathname,
      userId: state.userSession.token
        ? jwtDecode(state.userSession.token).sub
        : '',
      sharing: state.sharing.data.entries,
      currentUserRoles: state.users?.user?.roles,
    }),
    { getUser, getSharing, setSiteInfo, getSiteInfo },
  ),
  withRouter,
)(Admin);
