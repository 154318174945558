/**
 * Header component.
 * @module components/theme/Header/Header
 */

import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  Button,
  Container,
  Dropdown,
  Image,
  Modal,
  Segment,
} from 'semantic-ui-react';

import { getUser, getSharing } from '@plone/volto/actions';
import {
  Anontools,
  Icon as PloneIcon,
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';
import { Pluggable } from '@plone/volto/components/manage/Pluggable';
import PersonalInformation from '@plone/volto/components/manage/Preferences/PersonalInformation';
import PersonalPreferences from '@plone/volto/components/manage/Preferences/PersonalPreferences';
import { userHasRoles } from '@plone/volto/helpers';
import clearSVG from '@plone/volto/icons/clear.svg';
import rightKey from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  logout: {
    id: 'Logout',
    defaultMessage: 'Logout',
  },
  preferences: {
    id: 'Preferences',
    defaultMessage: 'Preferences',
  },
  profile: {
    id: 'Profile',
    defaultMessage: 'Profile',
  },
  userAvatar: {
    id: 'user avatar',
    defaultMessage: 'user avatar',
  },
  siteSetup: {
    id: 'Site Setup',
    defaultMessage: 'Site Setup',
  },
  admin: {
    id: 'Admin settings',
    defaultMessage: 'Admin settings',
  },
  save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
});

const modalComponentMapping = {
  preferences: PersonalInformation,
  profile: PersonalPreferences,
};

function UserMenuModal({
  open,
  title,
  component,
  onSubmit,
  onCancel,
  onClose,
}) {
  const ModalContent = component ? modalComponentMapping[component] : null;

  if (!open) {
    return null;
  }

  return (
    <Modal open={open}>
      <Modal.Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {title ? <h2 style={{ marginBlock: 0 }}>{title}</h2> : null}
          <Button
            style={{ marginInlineStart: 'auto' }}
            basic
            circular
            primary
            onClick={onClose}
          >
            <span className="sr-only">close</span>
            <PloneIcon
              name={clearSVG}
              className="contents"
              color="red"
              style={{ margin: 0 }}
              size="40px"
            />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content style={{ padding: 0 }}>
        <ModalContent
          onSubmit={onSubmit}
          onCancel={onCancel}
          closeMenu={onClose}
        />
      </Modal.Content>
      {/* Actions are all handled by the contents */}
    </Modal>
  );
}

function UserMenuItem({ title, iconName, onClick }) {
  return <Dropdown.Item icon={iconName} text={title} onClick={onClick} />;
}

function fetchUser(token) {
  return getUser(token ? jwtDecode(token).sub : '');
}

function UserMenu({ token, style = {} }) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalComponent, setModalComponent] = React.useState(null);

  const user = useSelector((state) => state.users.user);
  const portrait = flattenToAppURL(user?.portrait);
  const previousToken = React.useRef('');

  function closeModal() {
    setModalComponent(null);
    dispatch(fetchUser(token));
  }

  React.useEffect(() => {
    if (token !== previousToken.current) {
      previousToken.current = token;
      dispatch(fetchUser(token));
    }
  }, [dispatch, token]);

  React.useEffect(() => {
    dispatch(getSharing(flattenToAppURL('/'), user.id));
  }, [dispatch, user.id]);
  const showAdminLink = useSelector((state) => {
    const entries = state.sharing?.data?.entries;
    const usersSharing = entries?.find((u) => u.id === user.id);
    if (usersSharing) {
      return usersSharing.roles['Administrator'];
    }
    return false;
  });

  return (
    <>
      <UserMenuModal
        open={!!modalComponent}
        title={
          !!modalComponent ? intl.formatMessage(messages[modalComponent]) : null
        }
        component={modalComponent}
        onSubmit={closeModal}
        onCancel={closeModal}
        onClose={closeModal}
      />
      <div style={{ paddingInline: '0.5rem', ...style }}>
        <Dropdown
          direction={"right"}
          icon=""
          trigger={
            <div style={{ display: 'flex', marginBlockStart: '4px' }}>
              <Image
                style={{
                  height: '2.2rem',
                  width: 'auto',
                  marginInlineEnd: '-3px',
                }}
                avatar
                src={portrait}
              />
              <span
                style={{
                  paddingBlockStart: '3px',
                }}
              >
                <PloneIcon
                  aria-hidden="true"
                  name={rightKey}
                  className="contents"
                  color="rgba(0, 0, 0, 0.87)"
                  size="26px"
                />
              </span>
            </div>
          }
        >
          <Dropdown.Menu direction={"right"}>
            <Dropdown.Item>
              {user ? (
                <p>
                  Signed in as
                  <br />
                  <strong>{user.fullname ? user.fullname : user.email}</strong>
                </p>
              ) : null}
            </Dropdown.Item>

            <Dropdown.Divider />

            {Object.keys(modalComponentMapping).map((modalName) => {
              return (
                <UserMenuItem
                  key={modalName}
                  title={intl.formatMessage(messages[modalName])}
                  onClick={() => setModalComponent(modalName)}
                />
              );
            })}

            {showAdminLink ? (
              <>
                <Dropdown.Divider />
                <UserMenuItem
                  title={intl.formatMessage(messages.admin)}
                  onClick={() => {
                    history.push('/admin');
                  }}
                />
              </>
            ) : null}

            {userHasRoles(user, ['Site Administrator', 'Manager']) && (
              <>
                <Dropdown.Divider />
                <UserMenuItem
                  title={intl.formatMessage(messages.siteSetup)}
                  onClick={() => {
                    history.push('/controlpanel');
                  }}
                />
              </>
            )}

            <Pluggable name="toolbar-user-menu" />

            <Dropdown.Divider />
            <UserMenuItem
              title={intl.formatMessage(messages.logout)}
              onClick={() => {
                history.push('/logout');
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Segment basic className="header-wrapper" role="banner">
        <Container>
          <div className="header">
            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo />
              </div>
              <Navigation pathname={this.props.pathname} />
              <div className="tools-search-wrapper">
              <LanguageSelector />
              {!this.props.token && (
                <div className="tools">
                  <Anontools />
                </div>
              )}
              {this.props.token ? (
                <>
                  <div className="search">
                    <SearchWidget />
                  </div>
                  <UserMenu
                    token={this.props.token}
                    style={{ marginInlineStart: '1.5rem' }}
                  />
                </>
              ) : null}
            </div>

            </div>

          </div>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({
  token: state.userSession.token,
}))(Header);


