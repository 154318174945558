import { POST_SEND_OTP } from '../../constants/ActionTypes';

export function postSendOTP(otp, email, qs_email, qs_otp) {
  return {
    type: POST_SEND_OTP,
    request: {
      op: 'post',
      path: `/@submitOTP`,
      data: {
        login: email,
        login_enc: qs_email,
        otp: otp,
        otp_enc: qs_otp,
      },
    },
  };
}
