import { GET_SEARCHUSERS } from '../../constants/ActionTypes';

export function searchUsers(url, query) {
  return {
    type: GET_SEARCHUSERS,
    request: {
      op: 'get',
      path: `${url}/@sharing?search=${query}`,
    },
  };
}
