import { getBaseUrl } from '@plone/volto/helpers';
import { find, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Icon as IconOld,
  Search,
  Label,
  Segment,
  Form,
  Select,
  Radio,
  TextArea,
  Button,
} from 'semantic-ui-react';
import { searchUsers } from '~/actions/users/searchUsers';
import { withRouter } from 'react-router-dom';

const messages = defineMessages({
  user: {
    id: 'User',
    defaultMessage: 'User',
  },
});

class UserSearch extends Component {
  static propTypes = {
    searchUsers: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    selectedUsers: PropTypes.array,
    updateUsers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedUsers: [],
  };

  constructor(props) {
    super(props);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.doUserSearch = this.doUserSearch.bind(this);
    this.processUserSearch = this.processUserSearch.bind(this);
    this.state = {
      search: '',
      selectedUsers: this.props.selectedUsers,
    };
  }

  doUserSearch(search) {
    // TODO: Get debounce working
    this.props.searchUsers(getBaseUrl(this.props.pathname), search);
    /*_.debounce(
      () => this.props.searchUsers(getBaseUrl(this.props.pathname), search),
      50,
    );*/
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.searchUsersRequest.get.loading &&
      nextProps.searchUsersRequest.get.loaded
    ) {
      this.processUserSearch(nextProps.searchUsersRequest.userInfo.entries);
    }
  }

  processUserSearch(entries, searchInput) {
    // Generate list of users from search results
    let users = map(
      entries.filter((entry) => {
        if (
          Object.values(entry.roles).every((value) => value === false) &&
          !find(this.props.entries, { id: entry.id }) &&
          !this.props.selectedUsers.includes(entry.id) &&
          entry.type !== 'group'
        ) {
          return true;
        }
        return false;
      }),
      (entry) => ({
        title: entry.id.toString(),
      }),
    );

    // Add any valid email addresses from search that don't match current results
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    // When called from onChangeSearch function, the state is not yet in sync
    // so in that case we use the passed in searchInput variable.
    const search = searchInput || this.state.search;
    if (
      emailRegex.test(search) &&
      !this.props.selectedUsers.includes(search) &&
      !find(this.props.entries, { id: search })
    ) {
      users = [{ title: search }].concat(
        users.filter((e) => e.title !== search),
      );
    } else {
      // Remove any previous search result not already a user
      // This filter is possibly redundant as userid must be valid email address
      if (!entries.filter((e) => e.title === search).length) {
        users = users.filter((e) => e.title !== search);
      }
    }
    this.setState({ users: users });
  }

  onChangeSearch(event) {
    let search = event.target.value?.trimStart() || ''
    this.setState({
      search: search,
    });

    const confirmChars = [",", ";", " "];
    if (confirmChars.includes(search.slice(-1)) && this.state.users.length > 0){
      let newUsers = this.props.selectedUsers;
      newUsers.push(this.state.users[0].title);
      this.props.updateUsers(newUsers);
      search = '';
      this.setState({
        search: search,
        users: [],
      });
    }

    if (search.length > 2) {
      this.doUserSearch(search);
    } else {
      this.processUserSearch([], search);
    }
  }

  render() {
    return (
      <>
        {this.props.selectedUsers.length ? (
          <Segment basic>
            {this.props.selectedUsers.map((user) => {
              return (
                <Label key={'selectedUser-' + user.toString()} size="large">
                  <IconOld
                    name="user"
                    title={this.props.intl.formatMessage(messages.user)}
                  />
                  {user}
                  <IconOld
                    name="delete"
                    onClick={() =>
                      this.props.updateUsers(
                        this.props.selectedUsers.filter((e) => e !== user),
                      )
                    }
                    style={{ fontWeight: 900 }}
                  />
                </Label>
              );
            })}
          </Segment>
        ) : null}
        <Segment basic>
          <Search
            id="invite-users"
            input={{ fluid: true }}
            fluid={true}
            className="customSearch"
            showNoResults={false}
            selectFirstResult={true}
            icon=""
            value={this.state.search}
            placeholder=" Begin typing email address to share workspace"
            onSearchChange={this.onChangeSearch}
            onResultSelect={(e, data) => {
              let newUsers = this.props.selectedUsers;
              newUsers.push(data.result.title);
              this.props.updateUsers(newUsers);
              this.setState({
                search: '',
              });
            }}
            results={this.state.users}
            resultRenderer={({ title }) => {
              return (
                <p>
                  <IconOld
                    name="user"
                    title={this.props.intl.formatMessage(messages.user)}
                  />
                  {title}
                </p>
              );
            }}
          />
        </Segment>
        {this.props.selectedUsers.length ? (
          <Segment basic>
            <Form onSubmit={this.props.onShare}>
              <Segment basic>
                <Form.Field
                  control={Select}
                  //value={this.getRoleValue(entry)}
                  onChange={this.props.handleInputChange}
                  defaultValue={this.props.form.permission}
                  width={4}
                  inline
                  name="permission"
                  label="Select permission level for new user"
                  options={this.props.availableRoles.map((role) => ({
                    key: role.id,
                    text: (
                      <p>
                        <strong>{role.label || role.id}</strong>
                        <br />
                        {role.title}
                      </p>
                    ),
                    value: role.id,
                  }))}
                />
              </Segment>
              {this.props.emailNotification && (
                <>
                  <Segment basic>
                    <Radio
                      toggle
                      name="sendLinkToAccessContent"
                      defaultChecked={this.props.form.sendLinkToAccessContent}
                      label="Send user(s) an email with sharing link?"
                      onChange={this.props.handleInputChange}
                    />
                  </Segment>
                  {(this.props.form.sendLinkToAccessContent)  ? (
                    <Segment basic attached>
                      <p>You can enter some custom text which will appear at the top of the email:</p>
                      <TextArea
                        name="customEmail"
                        placeholder="Enter custom email text"
                        style={{ minHeight: 100 }}
                        onChange={this.props.handleInputChange}
                      />
                    </Segment>
                  ) : null}
                </>
              )}
              <Segment basic compact>
                <Button type="submit">Share</Button>
              </Segment>
            </Form>
          </Segment>
        ) : null}
      </>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      searchUsersRequest: state.searchUsers,
      pathname: props.location.pathname,
      selectedUsers: props.selectedUsers,
      updateUsers: props.updateUsers,
    }),
    { searchUsers },
  ),
)(UserSearch);
