/**
 * Contents upload modal.
 * @module components/manage/Contents/ContentsUploadModal
 */

import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
//  Dimmer,
  Header,
  Icon as IconOld,
// Image,
// Loader,
  Modal,
  Table,
  Segment,
  Progress,
  Message,
} from 'semantic-ui-react';
import loadable from '@loadable/component';
import { map } from 'lodash';
import filesize from 'filesize';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  useIntl,
} from 'react-intl';
import { FormattedRelativeDate, Icon } from '@plone/volto/components';
//import { createContent } from '@plone/volto/actions';
//import { validateFileUploadSize } from '@plone/volto/helpers';
import TusUploady from '@rpldy/tus-uploady';
import {
  useUploady,
  useRequestPreSend,
  useBatchAddListener,
  useBatchProgressListener,
  useBatchFinishListener,
  useBatchErrorListener,
  useItemErrorListener,
  useAbortAll,
  useAllAbortListener,
} from '@rpldy/uploady';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import readySVG from '@plone/volto/icons/ready.svg';
import mistakeSVG from '@plone/volto/icons/mistake.svg';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
  upload: {
    id:
      '{count, plural, one {Upload {count} file} other {Upload {count} files}}',
    defaultMessage:
      '{count, plural, one {Upload {count} file} other {Upload {count} files}}',
  },
  complete: {
    id: 'Upload Complete',
    defaultMessage: 'Upload Complete',
  },
  error: {
    id: 'Upload Failed',
    defaultMessage: 'Upload Failed',
  },
});

const MEGABYTE_FORMATTER = Intl.NumberFormat('en', {
  notation: 'standard',
  compactDisplay: 'long',
  style: 'unit',
  unit: 'megabyte',
  unitDisplay: 'narrow',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});
// TODO: Speed
// const dateFormatterConfig = {
//   style: 'unit',
//   unit: 'second',
//   unitDisplay: 'narrow',
// };
// const DATE_DIVISIONS = [
//   { amount: 60, name: 'second' },
//   { amount: 60, name: 'minute' },
//   { amount: 24, name: 'hour' },
//   { amount: 7, name: 'day' },
//   { amount: 4.34524, name: 'week' },
//   { amount: 12, name: 'month' },
//   { amount: Number.POSITIVE_INFINITY, name: 'year' },
// ];
// function convertToFriendlyDate(duration) {
//   for (const division of DATE_DIVISIONS) {
//     if (Math.abs(duration) < division.amount) {
//       const formatter = Intl.NumberFormat(navigator.languages, {
//         ...dateFormatterConfig,
//         unit: division.name,
//       });
//       return `${formatter.format(Math.round(duration))} left`;
//     }
//     duration /= division.amount;
//   }
// }

const RemoveButton = ({ item, removeFile }) => {
  const { abort } = useUploady();

  return item?.state !== 'finished' ? (
    <IconOld
      name="close"
      link
      onClick={() => {
        removeFile(item, abort);
      }}
    />
  ) : null;
};

function ItemProgress({ item }) {
  const intl = useIntl();
  const actual_progress = item?.completed || 0;
  const completed = actual_progress - actual_progress / 90;

  if (item?.state === 'added') {
    return null;
  }

  if (item?.state === 'uploading') {
    return (
      <Progress
        aria-label={`Progress ${completed}%`}
        percent={completed}
        indicating
        style={{ marginTop: '1.5em' }}
      />
    );
  }
  if (item?.state === 'finished') {
    return (
      <Icon
        name={readySVG}
        aria-label="Upload Finished"
        color="green"
        size="30px"
        title={intl.formatMessage(messages.complete)}
      />
    );
  }
  if (item?.state === 'error') {
    return (
      <Icon
        name={mistakeSVG}
        aria-label="Upload Failed"
        color="red"
        title={intl.formatMessage(messages.error)}
      />
    );
  }

  // Don't display in unknown state case
  return null;
}

function BrowseButton() {
  useRequestPreSend(({ items, options }) => {
    let ctype = 'File';
    if (items[0]['file']['type'].startsWith('image')) {
      ctype = 'Image';
    }
    if (items[0]['file']['type'].startsWith('video')) {
      ctype = 'Video';
    }

    return {
      options: {
        params: {
          filename: items[0]['file']['name'],
          'Content-type': items[0]['file']['type'],
          '@type': ctype,
        },
      },
    };
  });
  const uploady = useUploady();

  const onClick = () => {
    uploady.showFileUpload({ autoUpload: true });
  };
  return (
    <button onClick={onClick} className="ui button primary">
      <FormattedMessage id="Browse" defaultMessage="Browse" />
    </button>
  );
}

function CloseButton({ handleClose }) {
  const intl = useIntl();
  const abortAll = useAbortAll();
  const onClose = () => {
    handleClose(abortAll);
  };
  return (
    <Button
      basic
      circular
      secondary
      icon="remove"
      title={intl.formatMessage(messages.close)}
      floated="right"
      size="big"
      onClick={onClose}
    />
  );
}

function DropZoneArea({ itemError, updateItems }) {
  const { upload } = useUploady();

  useBatchAddListener((batch) => {
    updateItems(batch.id, batch.items);
  });

  useBatchProgressListener((batch) => {
    updateItems(batch.id, batch.items);
  });

  useBatchFinishListener((batch) => {
    // Aborted items are removed from the final listing in the UI
    const cleanedBatchItems = batch.items.filter(
      (item) => item.state !== 'aborted',
    );
    updateItems(batch.id, cleanedBatchItems);
  });

  useItemErrorListener((batch) => {
    itemError(batch);
  });
  useBatchErrorListener((batch) => {
    console.error('An error happened with the batch upload');
  });

  const onDrop = (files) => {
    upload(files, { autoUpload: true });
  };

  return (
    <Dropzone
      onDrop={onDrop}
      className="dropzone"
      noDragEventsBubbling={true}
      multiple={true}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: 'dashed' })}>
          <Segment>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="Drag and drop files from your computer onto this area or click the “Browse” button."
                      defaultMessage="Drag and drop files from your computer onto this area or click the “Browse” button."
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <BrowseButton />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </div>
      )}
    </Dropzone>
  );
}

function UploadInfo({ batches }) {
  const previouslySentData = React.useRef(0);
  const [sentData, setSentData] = React.useState(0);

  const totalFileSize = React.useMemo(() => {
    return Object.values(batches)
      .flat()
      .reduce((total, batch) => {
        return total + batch.file.size;
      }, 0);
  }, [batches]);

  // TODO: Speed. This add listener doesn't trigger for the first one for some reason so we're not doing speed for now
  // useBatchAddListener((batch) => {
  //   // updateTotalFileSize(additionalFileSize);
  //   // const shouldUpdateStartTime =
  //   //   finished.length > 0 && Object.values(finished).every(Boolean);
  //   // if (shouldUpdateStartTime) {
  //   //   resetUpload();
  //   // }
  // });
  // finishUpload() {
  //   setUploadStartTime(0);
  // }
  // resetUpload() {
  //   this.setState({
  //     uploadsStartTime: Date.now(),
  //     previouslySentData: 0,
  //     sentData: 0,
  //   });
  // }
  // const timeTaken = Date.now() - this.state.uploadsStartTime;
  // const uploadSpeed = (this.state.sentData / timeTaken) * 1000;
  // const friendlyTimeRemaining = convertToFriendlyDate(
  //   (this.state.totalFileSizeToUpload - this.state.sentData) / uploadSpeed,
  // );

  useBatchProgressListener((batch) => {
    const dataToAdd = batch.loaded - previouslySentData.current;
    if (dataToAdd) {
      previouslySentData.current = batch.loaded;
      updateUpload(dataToAdd);
    }
  });

  useBatchFinishListener((batch) => {
    previouslySentData.current = 0;
    // TODO: Speed
    // const allBatchesFinished = Object.values(finished).every(Boolean);
    // if (allBatchesFinished) {
    //   finishUpload();
    // }
  });

  useAllAbortListener(() => {
    previouslySentData.current = 0;
    setSentData(0);
  });

  function updateUpload(dataToAdd) {
    setSentData((currentAmountOfData) => currentAmountOfData + dataToAdd);
  }

  let friendlyTotalFileSize;
  if (totalFileSize >= 10000000000) {
    friendlyTotalFileSize = filesize(totalFileSize, {
      round: 2,
    });
  } else if (totalFileSize > 1000000000) {
    friendlyTotalFileSize = MEGABYTE_FORMATTER.format(totalFileSize / 1000000);
  } else {
    friendlyTotalFileSize = filesize(totalFileSize, {
      round: 0,
    });
  }
  let friendlySentData;
  if (sentData >= 10000000000) {
    friendlySentData = MEGABYTE_FORMATTER.format(sentData / 1000000);
  } else if (sentData > 1000000000) {
    friendlySentData = MEGABYTE_FORMATTER.format(sentData / 1000000);
  } else {
    friendlySentData = filesize(sentData, { round: 0 });
  }

  return (
    <>
      <span className="sr-only">Data sent: </span>
      {friendlySentData}/{friendlyTotalFileSize}
      {/* TODO: Speed */}
      {/* {showTimeRemaining ? `,&nbsp;${timeRemaining}` : null} */}
    </>
  );
}

/**
 * ContentsUploadModal class.
 * @class ContentsUploadModal
 * @extends Component
 */
class ContentsUploadModal extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    uploadURL: PropTypes.string.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs ContentsUploadModal
   */
  constructor(props) {
    super(props);

    this.updateItems = this.updateItems.bind(this);
    this.itemError = this.itemError.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      batches: {},
      error: {},
    };
  }
  updateItems(batchId, items) {
    this.setState((prevState) => {
      return { batches: { ...prevState.batches, [batchId]: items } };
    });
  }

  itemError(item) {
    this.setState((prevState) => {
      return { error: { ...prevState.error, [item.id]: item.uploadResponse } };
    });
  }

  removeFile(abortedItem, abort) {
    abort(abortedItem.id);
    this.setState((prevState) => {
      return {
        batches: {
          ...prevState.batches,
          [abortedItem.batchId]: prevState.batches[abortedItem.batchId].filter(
            (item) => abortedItem.id !== item.id,
          ),
        },
      };
    });
  }

  handleClose(abortAll) {
    abortAll();
    this.props.onClose(Object.keys(this.state.batches).length > 0);
    this.setState({
      batches: {},
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const authToken = document.cookie.replace(
      /(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/,
      '$1',
    );
    const uploadURL = '/++api++' + this.props.uploadURL + '/@tus-upload';

    /* Currently only using TusUploady, but in future could be expanded
     * to use non tus version
     */
    const Uploader = TusUploady;
    const uploadProps = {
      destination: {
        url: uploadURL,
        headers: {
          Authorization: `Bearer ${authToken}`,
          accept: 'application/json',
        },
      },
      chunkSize: 52428800, // 50MB
      sendDataOnCreate: false,
      sendWithFormData: true,
      withCredentials: true,
    };

    const items = Object.values(this.state.batches).flat();

    return (
      this.props.open && (
        <Modal open={this.props.open}>
          <Uploader {...uploadProps}>
            <Header>
              <FormattedMessage
                id="Upload files"
                defaultMessage="Upload files"
              />
            </Header>
            <Modal.Content>
              <DropZoneArea
                updateItems={this.updateItems}
                itemError={this.itemError}
              />
              {items.length > 0 && (
                <Table compact singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={8}>
                        <FormattedMessage
                          id="Filename"
                          defaultMessage="Filename"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage
                          id="Last modified"
                          defaultMessage="Last modified"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage
                          id="File size"
                          defaultMessage="File size"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        <FormattedMessage
                          id="Progress"
                          defaultMessage="Progress"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {map(items, (file, index) => {
                      return (
                        <React.Fragment key={file.id}>
                          <Table.Row className="upload-row">
                            <Table.Cell>{file.file.name}</Table.Cell>
                            <Table.Cell>
                              {file.file.lastModifiedDate && (
                                <FormattedRelativeDate
                                  date={file.file.lastModifiedDate}
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              {filesize(file.file.size, { round: 0 })}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              verticalAlign="middle"
                              style={{ height: '6em' }}
                            >
                              <ItemProgress item={file} />
                            </Table.Cell>
                            <Table.Cell>
                              <RemoveButton
                                item={file}
                                removeFile={this.removeFile}
                              />
                            </Table.Cell>
                          </Table.Row>
                          {this.state.error[file.id] ? (
                            <>
                              <Table.Row>
                                <Table.Cell colSpan="5">
                                  <Message error>
                                    <Message.Header>
                                      Upload Failed
                                    </Message.Header>
                                    <p>{this.state.error[file.id]}</p>
                                  </Message>
                                </Table.Cell>
                              </Table.Row>
                            </>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                    <Table.Row
                      style={{
                        fontVariantNumeric: 'tabular-nums',
                        textAlign: 'right',
                      }}
                    >
                      <Table.Cell colSpan={5}>
                        <UploadInfo batches={this.state.batches} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Modal.Content>
            <Modal.Actions>
              <CloseButton handleClose={this.handleClose} />
            </Modal.Actions>
          </Uploader>
        </Modal>
      )
    );
  }
}

export default compose(injectIntl)(ContentsUploadModal);
