/**
 * Users reducer.
 * @module reducers/users/users
 */


import { GET_USERINFO } from '../../constants/ActionTypes';

const initialState = {
  userInfo: {},
  get: {
    error: null,
    loaded: false,
    loading: false,
  },
};


/**
 * Users reducer.
 * @function users
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function userInfo(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_USERINFO}_PENDING`:
      return {
        ...state,
        get: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_USERINFO}_SUCCESS`:
      return {
        ...state,
        userInfo: action.result,
        get: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_USERINFO}_FAIL`:
      return {
        ...state,
        userInfo: {},
        get: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
