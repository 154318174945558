import { GET_SHARES } from '../../constants/ActionTypes';

const initialState = {
  link: null,
  shares: {
    error: null,
    loaded: false,
    loading: false,
  },
};

export default function getSharingLink(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_SHARES}_PENDING`:
      return {
        ...state,
        link: null,
        shares: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_SHARES}_SUCCESS`:
      return {
        ...state,
        link: action.result.link,
        shares: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_SHARES}_FAIL`:
      return {
        ...state,
        link: 'Error',
        shares: {
          loading: false,
          loaded: false,
          error: action.error.response.error,
        },
      };
    default:
      return state;
  }
}
