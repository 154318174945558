import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Image } from 'semantic-ui-react';

const CardListing = ({ items, isEditMode }) => {
  return (
    <>
      <Card.Group>
        {items.map((item) => {
          return (
            <Card
              key={item['@id']}
              href={isEditMode ? null : flattenToAppURL(item['@id'])}
            >
              <Image src={item?.['preview_image']?.download} />
              <Card.Content header={item.title ? item.title : item.id} />
              <Card.Content description={item.description} />
            </Card>
          );
        })}
      </Card.Group>
    </>
  );
};

CardListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default CardListing;
