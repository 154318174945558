import { GET_WORKSPACES, POST_ADD_OWNER } from '~/constants/ActionTypes';

export function addOwnerForWorkspace(options) {
  const { userId, workspacePath } = options || {};

  return {
    type: POST_ADD_OWNER,
    request: {
      op: 'post',
      path: `/@admin-addOwnerForWorkspace`,
      data: {
        userId,
        workspacePath,
      },
    },
  };
}

export function getWorkspaces(options) {
  const { batchSize = 10, batchStart = 0 } = options || {};

  return {
    type: GET_WORKSPACES,
    request: {
      op: 'get',
      path: `/@admin-workspaces?b_size=${batchSize}&b_start=${batchStart}`,
    },
  };
}
