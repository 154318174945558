import { POST_SHARES } from '../../constants/ActionTypes';

const initialState = {
  status: null,
  shares: {
    error: null,
    loaded: false,
    loading: false,
  },
};

export default function shares(state = initialState, action = {}) {
  switch (action.type) {
    case `${POST_SHARES}_PENDING`:
      return {
        ...state,
        status: null,
        shares: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${POST_SHARES}_SUCCESS`:
      return {
        ...state,
        status: action.result.status,
        shares: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${POST_SHARES}_FAIL`:
      return {
        ...state,
        status: 'Error',
        shares: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
