import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import {
  Api,
  flattenToAppURL,
  getParentUrl,
  isInternalURL,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import filesize from 'filesize';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const MimeToFriendlyNameMapping = {
  'application/pdf': 'PDF',
};

const FileListing = ({ item }) => {
  const [fileContent, setFileContent] = useState(null);
  const api = new Api();
  api.get(item.url).then((response) => {
    setFileContent(response?.file);
  });

  if (!fileContent) {
    return `Loading ${item.title}...`;
  }

  return (
    <a
      download
      href={flattenToAppURL(fileContent.download)}
      className="govuk-link govuk-!-margin-top-2"
      style={{ fontSize: '19px', fontWeight: 'bold', display: 'block' }}
    >
      {`Download ${item.title} (${
        MimeToFriendlyNameMapping[fileContent['content-type']]
      }, ${filesize(fileContent.size)})`}
    </a>
  );
};

const VideoListing = ({ item }) => {
  return (
    <>
      <h2 className="govuk-heading-l govuk-!-margin-bottom-2">{item.title}</h2>
      <p className="govuk-body">{item.description}</p>
      <video
        src={
          isInternalURL(
            item.url.replace(getParentUrl(config.settings.apiPath), ''),
          )
            ? `${item.url}/@@download/file`
            : item.url
        }
        controls
        poster={
          item.preview_image
            ? isInternalURL(item.preview_image)
              ? `${flattenToAppURL(item.preview_image)}/@@images/image`
              : item.preview_image
            : ''
        }
        type="video/mp4"
      >
        <track kind="captions" src="" srclang="en" label="English" />
      </video>
    </>
  );
};

const Sidebar = ({ items, selectedItem, onItemSelect }) => {
  return (
    <ul className="library-listing__sidebar">
      {items.map((item) => {
        if (item['@type'] === 'Folder') {
          return (
            <Link key={item.url} className="govuk-button" to={item.url}>
              {item.title}
            </Link>
          );
        }

        return (
          <li
            className={`library-listing__sidebar__link${
              item === selectedItem ? ' active' : ''
            }`}
            key={item.url}
          >
            <button
              className="govuk-button govuk-link"
              onClick={() => {
                onItemSelect(item);
              }}
              to={item.url}
            >
              {item.title}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

const UnselectedItemView = () => {
  return null;
};

const fileExtensionToComponentMapping = {
  pdf: FileListing,
  mp4: VideoListing,
  null: UnselectedItemView,
  undefined: UnselectedItemView,
};

const LibraryListing = ({ items, openObjectBrowser }) => {
  // React set state to store the item and a function to update the item
  const [selectedItem, setSelectedItem] = useState(null);
  const fileExtension = selectedItem?.url.split('.').pop();
  let SelectedItemView = fileExtensionToComponentMapping[fileExtension];

  const onItemSelect = (item) => {
    setSelectedItem(item);
  };

  let location = useLocation();
  useEffect(() => {
    setSelectedItem(null);
  }, [location]);

  return (
    <>
      <div>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-quarter govuk-!-padding-right-0">
            <Sidebar
              onItemSelect={onItemSelect}
              items={items}
              selectedItem={selectedItem}
            />
          </div>
          {selectedItem ? (
            <div
              className="govuk-grid-column-three-quarters"
              style={{ borderLeft: '1px solid #b1b4b6' }}
            >
              <>
                <Link
                  className="govuk-link"
                  to="#"
                  onClick={() => {
                    openObjectBrowser({
                      mode: 'link',
                      currentPath: '/',
                      onSelectItem: (url, item) => {
                        console.log(`Adding to workspace ${item.title}`);
                      },
                    });
                  }}
                >
                  Add to worksapce...
                </Link>
                <SelectedItemView item={selectedItem} />
              </>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const LibraryListingView = ({ content, openObjectBrowser }) => {
  return (
    <LibraryListing
      items={content.items}
      openObjectBrowser={openObjectBrowser}
    />
  );
};

export default withObjectBrowser(LibraryListingView);
