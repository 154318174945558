/**
 * Admin component.
 * @module components/Admin/Admin
 */

import React, { Component } from 'react';
import {
  getBaseUrl,
  Helmet,
  toPublicURL,
  messages as voltoMessages,
} from '@plone/volto/helpers';
import { Container } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import UserSearch from '~/components/Sharing/userSearch';
import { shares } from '~/actions/Sharing/shares';
import UserList from '~/components/Sharing/userList';
import { find, map } from 'lodash';
import { toast } from 'react-toastify';
import { getSharing, updateSharing } from '@plone/volto/actions';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Toolbar, Toast } from '@plone/volto/components';
import PropTypes from 'prop-types';

const messages = defineMessages({
  permissionsUpdated: {
    id: 'Permissions updated',
    defaultMessage: 'Permissions updated',
  },
  permissionsUpdatedSuccessfully: {
    id: 'Permissions have been updated successfully',
    defaultMessage: 'Permissions have been updated successfully',
  },
});

const formDefaults = {
  permission: 'Workspace Adder',
  customEmail: '',
  sendLinkToAccessContent: false,
}

/**
 * UserManagement component class.
 * @class UserManagement
 * @extends Component
 */
class UserManagement extends Component {
  static propTypes = {
    shares: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
      selectedUsers: [],
      availableRoles: [],
      form: formDefaults,
    };
    this.convertSharing = this.convertSharing.bind(this);
    this.filterRoles = this.filterRoles.bind(this);
    this.setSharing = this.setSharing.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getRoleEntries = this.getRoleEntries.bind(this);
    this.getRoleValue = this.getRoleValue.bind(this);
    this.onShareSuccess = this.onShareSuccess.bind(this);
    this.onShareFailure = this.onShareFailure.bind(this);
  }

  componentDidMount() {
    this.props.getSharing(getBaseUrl('/'));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.sharingRequest.get.loading &&
      nextProps.sharingRequest.get.loaded
    ) {
      this.setState({
        entries: this.convertSharing(nextProps.sharingRequest.data.entries),
      });
      this.setState({
        availableRoles: this.filterRoles(
          nextProps.sharingRequest.data.available_roles,
        ),
      });
    }
    if (this.props.createRequest.loading && nextProps.createRequest.loaded) {
      this.onShareSuccess();
    }
    if (this.props.createRequest.loading && nextProps.createRequest.error) {
      this.onShareFailure(nextProps.createRequest.error);
    }
    if (nextProps.updateRequest.loading) {
      this.setState({ loading: true });
    }
    if (
      !this.props.createRequest.loading &&
      this.props.updateRequest.loading &&
      nextProps.updateRequest.loaded
    ) {
      //this.props.getSharing(getBaseUrl(this.props.pathname), this.state.search);
      toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(messages.permissionsUpdated)}
          content={this.props.intl.formatMessage(
            messages.permissionsUpdatedSuccessfully,
          )}
        />,
      );
      this.setState({ loading: false });
      this.props.getSharing(getBaseUrl('/'));
    }
  }

  convertSharing(entries) {
    let filtered_entries = entries.filter((entry) => {
      if (entry.type === 'group') {
        return false;
      }
      return true;
    });
    return map(filtered_entries, (entry) => {
      const values = find(entries, { id: entry.id });
      return {
        ...entry,
        roles: values ? values.roles : entry.roles,
      };
    });
  }

  filterRoles(available_roles) {
    if (available_roles.length === 0) {
      return [];
    }
    let wanted_roles = available_roles.filter((item) => {
      if (item.id === 'Contributor') {
        return false;
      }
      if (item.id === 'Reviewer') {
        return false;
      }
      if (item.id === 'Editor') {
        return false;
      }
      if (item.id === 'Reader') {
        return false;
      }
      if (item.id === 'View Workspace') {
        return false;
      }
      if (item.id === 'Edit Workspace') {
        return false;
      }
      if (item.id === 'Share Workspace') {
        return false;
      }
      if (item.id === 'Workspace Adder') {
        item.label = 'Add Workspaces';
        item.title = 'Can add new workspace';
      }
      if (item.id === 'Administrator') {
        item.label = 'Manager';
        item.title = 'Can manage the system';
      }
      return true;
    });
    return wanted_roles;
  }

  setSharing() {
    this.setState({ loading: true });
    const userData = {
      email: this.state.selectedUsers,
      roles: this.getRoleEntries(this.state.form.permission),
      send_link: this.state.form.sendLinkToAccessContent,
      customEmail: this.state.form.customEmail,
    };
    this.setState({ form: formDefaults});
    this.props.shares(getBaseUrl('/'), userData);
  }
  onShareSuccess() {
    this.setState({
      loading: false,
      addUserError: undefined,
      showRemoveUserDialog: false,
      userToRemove: null,
      search: '',
      selectedUsers: [],
    });
    this.props.getSharing(getBaseUrl('/'), '');

    toast.success(
      <Toast
        success
        title={this.props.intl.formatMessage(voltoMessages.success)}
        content={this.props.intl.formatMessage(voltoMessages.userCreated)}
      />,
    );
  }
  onShareFailure(error) {
    this.setState({
      loading: false,
      addUserError: undefined,
      showRemoveUserDialog: false,
      userToRemove: null,
      search: '',
      selectedUsers: [],
    });
    this.props.getSharing(getBaseUrl('/'), '');

    toast.error(
      <Toast
        error
        title={this.props.intl.formatMessage(voltoMessages.error)}
        content={error.response.statusCode + ' ' + error.response.statusText}
      />,
    );
  }

  handleInputChange(event, { name, value, checked }) {
    this.setState({
      form: {
        ...this.state.form,
        [name]: checked || value,
      },
    });
  }

  getRoleEntries(role) {
    if (role === 'Administrator') {
      return {
        Contributor: true,
        'Workspace Adder': true,
        Administrator: true,
        'Share Workspace': true,
      };
    }
    if (role === 'Workspace Adder') {
      return {
        Contributor: true,
        'Workspace Adder': true,
      };
    }
    return {};
  }

  getRoleValue(user) {
    if (user.roles['Administrator'] === true) {
      return 'Administrator';
    }
    if (user.roles['Workspace Adder'] === true) {
      return 'Workspace Adder';
    }
  }

  render() {
    return (
      <>
        <p>
          Use this control panel to manage administrative access to Vaultmatix.
        </p>

        <h2>Administrative Access</h2>
        <UserSearch
          availableRoles={this.state.availableRoles}
          selectedUsers={this.state.selectedUsers}
          updateUsers={(users) => {
            this.setState({ selectedUsers: users });
          }}
          onShare={this.setSharing}
          emailNotification={true}
          handleInputChange={this.handleInputChange}
          entries={this.state.entries}
          form={this.state.form}
        />
        <h2>Administrators</h2>
        <p>The following users have administrative access to Vaultmatix:</p>
        <UserList
          loading={this.state.loading}
          getRoleEntries={(role) => this.getRoleEntries(role)}
          getRoleValue={(user) => this.getRoleValue(user)}
          availableRoles={this.state.availableRoles}
          entries={this.state.entries}
          updateSharing={this.props.updateSharing}
        />
      </>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      entries: state.sharing.data.entries,
      sharingRequest: state.sharing,
      updateRequest: state.sharing.update,
      createRequest: state.shares.shares,
    }),
    { getSharing, updateSharing, shares },
  ),
)(UserManagement);
