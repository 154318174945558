import { nestContent } from '@plone/volto/helpers';
import {
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  GET_WORKSPACES,
} from '~/constants/ActionTypes';

/**
 * Create content function.
 * @function createContent
 * @param {string} url Parent URL.
 * @param {Object} content Content data.
 * @returns {Object} Create content action.
 */
export function createWorkspace(url, content) {
  return {
    type: CREATE_WORKSPACE,
    mode: 'serial',
    request: { op: 'post', path: url, data: nestContent(content) },
  };
}

/**
 * Delete content function.
 * @function deleteContent
 * @param {string|Array} urls Content url(s).
 * @returns {Object} Delete content action.
 */
export function deleteWorkspace(url) {
  return {
    type: DELETE_WORKSPACE,
    mode: 'serial',
    request: { op: 'del', path: url },
  };
}
