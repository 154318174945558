import { GET_SITEINFO, POST_SITEINFO } from '@root/constants/ActionTypes';


export function getSiteInfo() {
  return {
    type: GET_SITEINFO,
    request: {
      op: 'get',
      path: `/@siteinfo`,
    },
  };
}

export function setSiteInfo(data) {
  return {
    type: POST_SITEINFO,
    request: {
      op: 'post',
      path: `/@siteinfo`,
      data: data,
    },
  };
}

