import { Icon } from '@plone/volto/components';
import { getBaseUrl } from '@plone/volto/helpers';
//import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { find, isEqual, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Form,
  Icon as IconOld,
  Segment,
  Table,
  Select,
  Dimmer,
  Loader,
  Modal,
} from 'semantic-ui-react';
import clearSVG from '@plone/volto/icons/clear.svg';
import deleteSVG from '@plone/volto/icons/delete.svg';

const messages = defineMessages({
  user: {
    id: 'User',
    defaultMessage: 'User',
  },
  permissionsUpdated: {
    id: 'Permissions updated',
    defaultMessage: 'Permissions updated',
  },
  permissionsUpdatedSuccessfully: {
    id: 'Permissions have been updated successfully',
    defaultMessage: 'Permissions have been updated successfully',
  },
});

class UserList extends Component {
  static propTypes = {
    updateSharing: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    available_roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    login: PropTypes.string,
    getRoleValue: PropTypes.func.isRequired,
    availableRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
    getRoleEntries: PropTypes.func.isRequired,
  };

  static defaultProps = {
    login: '',
    available_roles: [],
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateRoles = this.updateRoles.bind(this);
    this.onToggleRemoveUser = this.onToggleRemoveUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.state = {
      isClient: false,
      showRemoveUserDialog: false,
      userToRemove: null,
    };
    //this.props.setAvailableRoles(props.available_roles);
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  onSubmit(event) {
    const data = { entries: [] };
    event.preventDefault();
    if (this.props.inherit !== this.state.inherit) {
      data.inherit = this.state.inherit;
    }
    for (let i = 0; i < this.state.entries.length; i += 1) {
      const id = this.state.entries[i].id;
      const prop = this.props.entries.find((entry) => entry.id === id);
      if (!prop) {
        continue;
      }
      if (!isEqual(this.state.entries[i].roles, prop.roles)) {
        data.entries.push({
          id: this.state.entries[i].id,
          type: this.state.entries[i].type,
          roles: this.state.entries[i].roles,
        });
      }
    }
    this.props.updateSharing(getBaseUrl(this.props.pathname), data);
  }

  updateRoles(sharing, event, input) {
    event.preventDefault();
    const data = {
      entries: [
        {
          id: input['data-user'],
          type: 'user',
          roles: this.props.getRoleEntries(input.value),
        },
      ],
    };
    this.props.updateSharing(getBaseUrl(this.props.pathname), data);
  }

  onToggleRemoveUser(user) {
    this.setState({
      showRemoveUserDialog: !this.state.showRemoveUserDialog,
      userToRemove: user,
    });
  }

  removeUser(event) {
    event.preventDefault();
    this.setState({
      showRemoveUserDialog: false,
    });
    const data = {
      entries: [
        {
          id: this.state.userToRemove.id,
          type: 'user',
          roles: [],
        },
      ],
    };
    this.props.updateSharing(getBaseUrl(this.props.pathname), data);
  }

  onChange(event, { value }) {
    const [principal, role] = value.split(':');
    this.setState({
      entries: map(this.state.entries, (entry) => ({
        ...entry,
        roles:
          entry.id === principal
            ? {
                ...entry.roles,
                [role]: !entry.roles[role],
              }
            : entry.roles,
      })),
    });
  }

  render() {
    return (
      <>
        <Dimmer active={this.props.loading}>
          <Loader>Updating</Loader>
        </Dimmer>
        <Form onSubmit={this.onSubmit}>
          <Table padded basic="very" collapsing>
            <Table.Body>
              {this.props.entries?.map((entry) => {
                return (
                  <Table.Row key={entry.id}>
                    <Table.Cell>
                      <IconOld
                        name="user"
                        title={this.props.intl.formatMessage(messages.user)}
                      />{' '}
                      {entry.title}
                      {entry.login && ` (${entry.login})`}
                    </Table.Cell>
                    <Table.Cell>
                      {entry.id !== this.props.login ? (
                        <Form.Field
                          id={'role-select-' + entry.title}
                          data-user={entry.id}
                          control={Select}
                          value={this.props.getRoleValue(entry)}
                          onChange={(e, d) => this.updateRoles(this, e, d)}
                          options={this.props.availableRoles.map((role) => ({
                            key: role.id,
                            text: (
                              <p>
                                <strong>{role.label || role.id}</strong>
                                <br />
                                {role.title}
                              </p>
                            ),
                            value: role.id,
                          }))}
                        />
                      ) : (
                        <p>
                          <strong>
                            {find(this.props.availableRoles, {
                              id: this.props.getRoleValue(entry),
                            })?.label ||
                              find(this.props.availableRoles, {
                                id: this.props.getRoleValue(entry),
                              })?.id}
                          </strong>
                          <br />
                          {
                            find(this.props.availableRoles, {
                              id: this.props.getRoleValue(entry),
                            })?.title
                          }
                        </p>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {entry.id !== this.props.login ? (
                        <Button
                          type="button"
                          onClick={() => {
                            this.onToggleRemoveUser(entry);
                          }}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Form>
        {this.state.showRemoveUserDialog ? (
          <Modal
            defaultOpen={true}
            closeOnDimmerClick={true}
            closeOnEscape={true}
            onClose={() => this.onToggleRemoveUser(null)}
            size="mini"
          >
            <Modal.Header>Remove User</Modal.Header>
            <Modal.Content>
              Are you sure you wish to remove the user from the workspace.
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={(e) => {
                  this.onToggleRemoveUser(null);
                }}
                size="tiny"
              >
                <Icon name={clearSVG} size="20px" title="Cancel" />
                <span
                  style={{
                    position: 'relative',
                    bottom: '5px',
                  }}
                >
                  Cancel
                </span>
              </Button>
              <Button
                onClick={(e) => {
                  this.removeUser(e);
                }}
                size="tiny"
              >
                <Icon name={deleteSVG} size="20px" title="Delete" />
                <span
                  style={{
                    position: 'relative',
                    bottom: '5px',
                  }}
                >
                  Remove User
                </span>
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect((state, props) => ({
    createRequest: state.shares.shares,
    pathname: props.location.pathname,
    login: state.userSession.token
      ? jwtDecode(state.userSession.token).sub
      : '',
  })),
)(UserList);
