import { Checkbox } from 'semantic-ui-react';

import { UniversalLink } from '@plone/volto/components';
import { Api, flattenToAppURL } from '@plone/volto/helpers';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Dimmer, List, Loader, Segment, Table } from 'semantic-ui-react';

const tableTitleFieldMapping = {
  title: 'Title',
  '@type': 'Content type',
  '@id': 'URL',
  subjects: 'Tags',
  sharing: 'Sharing',
};

const tableFields = Object.keys(tableTitleFieldMapping);
const tableTitles = Object.values(tableTitleFieldMapping);
const csvHeaders = Object.entries(tableTitleFieldMapping).map(
  ([field, value]) => {
    return { label: value, key: field };
  },
);

const Entry = ({ item, onSelect }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Checkbox
          label=""
          id={`select-item-${item['@id']}`}
          name={`select-item-${item['@id']}`}
          type="checkbox"
          value={item['@id']}
          onChange={onSelect}
        />
      </Table.Cell>
      {tableFields.map((field) => {
        if (field === '@id') {
          return (
            <Table.Cell key={field}>
              <UniversalLink href={item?.[field]}>
                {flattenToAppURL(item?.[field])}
              </UniversalLink>
            </Table.Cell>
          );
        }
        if (field === 'sharing') {
          return (
            <Table.Cell key={field}>
              <List>
                {item?.[field].map((value) => {
                  return <List.Item key={value}>{value}</List.Item>;
                })}
              </List>
            </Table.Cell>
          );
        }
        return <Table.Cell key={field}>{item?.[field]}</Table.Cell>;
      })}
    </Table.Row>
  );
};

const ReportListing = ({ items, linkTitle, linkHref, isEditMode }) => {
  const [itemsWithData, setItemsWithData] = useState([]);
  useEffect(() => {
    const api = new Api();
    const itemDataPromises = items.map((item) => {
      return api.get(item['@id']).then((itemData) => {
        return api.get(`${item['@id']}/@sharing`).then((sharingResponse) => {
          const sharedUsersAndGroups = sharingResponse.entries
            .filter((entry) => {
              if (!(entry['@id'] === 'AuthenticatedUsers')) {
                if (entry?.roles?.Reader) {
                  return true;
                }
              }
              return false;
            })
            .map((entry) => {
              return entry.title;
            });
          return { ...itemData, sharing: sharedUsersAndGroups };
        });
      });
    });

    Promise.all(itemDataPromises).then((itemsData) => {
      setItemsWithData(itemsData);
    });
  }, [items]);

  const [selectedItems, setSelectedItems] = useState([]);

  const updateSelectedItems = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedItems((selectedItems) => [...selectedItems, value]);
    } else {
      setSelectedItems((selectedItems) =>
        selectedItems.filter((item) => item !== value),
      );
    }
  };

  const getCsvData = useMemo(() => {
    const data = selectedItems.map((item) => {
      let row = {};
      Object.entries(tableTitleFieldMapping).forEach(([field]) => {
        const itemWithData = itemsWithData.find((i) => i['@id'] === item);
        const itemValue = itemWithData?.[field] ?? '';
        row[field] = Array.isArray(itemValue)
          ? itemValue.join(', ')
          : itemValue;
      });

      return row;
    });

    return data;
  }, [selectedItems]);

  if (itemsWithData.length === 0) {
    return (
      <Dimmer active>
        <Loader indeterminate>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <>
      <Segment disabled={selectedItems.length > 0 ? null : true}>
        <CSVLink
          headers={csvHeaders}
          data={getCsvData}
          filename={`report-${DateTime.local().toLocaleString(
            DateTime.DATE_MED,
          )}.csv`}
          asyncOnClick={true}
          onClick={(event, done) => {
            done(selectedItems.length > 0);
          }}
        >
          Download report of selected users
        </CSVLink>
      </Segment>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {tableTitles.map((title) => {
              return (
                <Table.HeaderCell scope="col" key={title}>
                  {title}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {itemsWithData.map((item) => {
            return (
              <Entry
                onSelect={updateSelectedItems}
                key={item['@id']}
                item={item}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

ReportListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default ReportListing;
