import { messages as voltoMessages } from '@plone/volto/helpers';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { addOwnerForWorkspace, getWorkspaces } from '~/actions';
import { POST_ADD_OWNER } from '~/constants/ActionTypes';

import {
  Avatar,
  Icon,
  Pagination,
  Toast,
  UniversalLink,
} from '@plone/volto/components';
import { toast } from 'react-toastify';
import { Button, Dimmer, Image, Loader, Modal, Table } from 'semantic-ui-react';

import clearSVG from '@plone/volto/icons/clear.svg';
import userSVG from '@plone/volto/icons/user.svg';

const currentPageQueryKey = 'page';
const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  becomeOwner: {
    id: 'Become owner',
    defaultMessage: 'Become owner',
  },
  addingOwner: {
    id: 'Adding owner',
    defaultMessage: 'Adding owner',
  },
  makeAdmin: {
    id: 'Make admin',
    defaultMessage: 'Make admin',
  },
  workspaceManagement: {
    id: 'Workspace management',
    defaultMessage: 'Workspace management',
  },
  workspaceManagementPageDescription: {
    id: 'workspaceManagementPageDescription',
    defaultMessage:
      'Use this control panel to view all workspaces in the system and update administrator access where required.',
  },
  workspacePermissionRequiredModalTitle: {
    id: 'workspaceModalTitle',
    defaultMessage: 'Assign ownership',
  },
  workspacePermissionRequiredModalDescription: {
    id: 'workspaceModalDescription',
    defaultMessage: `You need permission to view the workspace "{workspaceTitle}".
Would you like to add yourself as an administrator for this workspace?`,
  },
  me: {
    id: 'Me',
    defaultMessage: 'Me',
  },
  canView: {
    id: 'Can view',
    defaultMessage: 'Can view',
  },
  canEdit: {
    id: 'Can edit',
    defaultMessage: 'Can edit',
  },
  canShare: {
    id: 'Can share',
    defaultMessage: 'Can share',
  },
});

// TODO: Adjust page size to be hard-coded to '10'
const pageSize = 10;

function AddAdminPermisionModal({ isOpen, onClose, workspace }) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state?.users?.user?.id);
  const addOwnerRequest = useSelector(
    (state) => state.addOwnerForWorkspace?.post,
  );

  React.useEffect(() => {
    if (!addOwnerRequest) {
      return;
    }
    if (workspace && !addOwnerRequest.error && addOwnerRequest.loaded) {
      const workspaceUrl = new URL(workspace['@id']);
      history.push(workspaceUrl.pathname);
      // This feels a bit hacky, but it's needed so we can re-open the page without redux using the previous request
      dispatch({ type: `${POST_ADD_OWNER}_RESET` });
    } else if (addOwnerRequest.error) {
      // TODO: More friendly error messages
      toast.error(
        <Toast
          error
          title={intl.formatMessage(voltoMessages.error)}
          content={addOwnerRequest.error.message || 'An unknown error occurred'}
        />,
      );
    }
  }, [addOwnerRequest, workspace]);

  return (
    <Modal
      open={isOpen}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      onClose={onClose}
      size="mini"
    >
      {addOwnerRequest?.loading ? (
        <Dimmer active>
          <Loader indeterminate>
            {intl.formatMessage(messages.addingOwner)}
          </Loader>
        </Dimmer>
      ) : null}
      <Modal.Header>
        {intl.formatMessage(messages.workspacePermissionRequiredModalTitle)}
      </Modal.Header>
      <Modal.Content className="whitespace-pre-line">
        {/* TODO: Add more information about the workspace */}
        {intl.formatMessage(
          messages.workspacePermissionRequiredModalDescription,
          {
            workspaceTitle: workspace?.['title'],
          },
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} size="tiny">
          <Icon name={clearSVG} size="20px" ariaHidden={true} />
          <span
            style={{
              position: 'relative',
              bottom: '5px',
            }}
          >
            {intl.formatMessage(messages.cancel)}
          </span>
        </Button>
        <Button
          onClick={() => {
            const workspaceUrl = new URL(workspace['@id']);
            dispatch(
              addOwnerForWorkspace({
                userId: currentUserId,
                workspacePath: workspaceUrl.pathname,
              }),
            );
          }}
          size="tiny"
        >
          <Icon name={userSVG} size="20px" ariaHidden={true} />
          <span
            style={{
              position: 'relative',
              bottom: '5px',
            }}
          >
            {intl.formatMessage(messages.becomeOwner)}
          </span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function UserAvatar({ user }) {
  const currentUserId = useSelector((state) => state?.users?.user?.id);

  return user.portrait ? (
    <Image
      className={user.id === currentUserId ? 'currentUser' : null}
      avatar
      src={user.portrait}
      alt={user.fullname || user.id}
      title={user.fullname || user.id}
    />
  ) : (
    <Icon
      name={userSVG}
      size="28px"
      className="ui avatar image default"
      color="white"
      title={user.fullname || user.id}
    />
  );
}

// TODO: Alt text and sr-only text for the '+1' to make it readable as a single link
function WorkspaceUsersTableCell({ users, workspace, onClick }) {
  const currentUserId = useSelector((state) => state?.users?.user?.id);

  return users ? (
    <UniversalLink
      onClick={(event) => {
        if (Object.keys(workspace.users).includes(currentUserId)) {
          return;
        }
        // TODO: Redirect the user to the sharing page if they try to become an owner
        onClick(event);
        event.preventDefault();
      }}
      href={`${workspace['@id']}/sharing`}
    >
      {users.length > 2 ? (
        <>
          <UserAvatar user={users[0]} />
          <Avatar text="+1" size={28} />
        </>
      ) : (
        users.map((user, index) => {
          return <UserAvatar key={index} user={user} />;
        })
      )}
    </UniversalLink>
  ) : null;
}

function WorkspaceManagement() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserId = useSelector((state) => state?.users?.user?.id);
  const data = useSelector((state) => state.getWorkspaces);
  const { search, pathname } = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(search), [
    search,
  ]);
  const currentPage = parseInt(searchParams.get(currentPageQueryKey), 10) || 0;

  React.useEffect(() => {
    dispatch(
      getWorkspaces({
        batchStart: pageSize * currentPage,
        batchSize: pageSize,
      }),
    );
  }, [currentPage, dispatch]);

  function updatePage(newValue) {
    searchParams.set(currentPageQueryKey, newValue);
    history.push({ pathname: pathname, search: `?${searchParams.toString()}` });
  }

  const [modalWorkspace, setModalWorkspace] = React.useState('');

  return (
    <>
      <AddAdminPermisionModal
        isOpen={!!modalWorkspace}
        onClose={() => {
          setModalWorkspace(null);
        }}
        workspace={modalWorkspace}
      />
      <h1>{intl.formatMessage(messages.workspaceManagement)}</h1>
      <p>{intl.formatMessage(messages.workspaceManagementPageDescription)}</p>
      {/* TODO: react-table anybody? */}
      <Table basic="very">
        <caption className="sr-only">Workspaces</caption>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell role="columnheader" scope="col">
              {intl.formatMessage(messages.title)}
            </Table.HeaderCell>
            <Table.HeaderCell role="columnheader" scope="col">
              {intl.formatMessage(messages.canView)}
            </Table.HeaderCell>
            <Table.HeaderCell role="columnheader" scope="col">
              {intl.formatMessage(messages.canEdit)}
            </Table.HeaderCell>
            <Table.HeaderCell role="columnheader" scope="col">
              {intl.formatMessage(messages.canShare)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.items?.map((workspace, index) => {
            const usersByRole = Object.entries(workspace.users)?.reduce(
              (usersByRole, [userId, userInfo]) => {
                userInfo.roles.forEach((role) => {
                  if (Array.isArray(usersByRole[role])) {
                    // Put the current admin first
                    if (userId === currentUserId) {
                      usersByRole[role].unshift(userInfo);
                    } else {
                      usersByRole[role].push(userInfo);
                    }
                  } else {
                    usersByRole[role] = [userInfo];
                  }
                });
                return usersByRole;
              },
              {},
            );

            return (
              <Table.Row key={index}>
                <Table.Cell
                  style={{ width: '70%' }}
                  scope="row"
                  role="rowheader"
                >
                  <UniversalLink
                    onClick={(event) => {
                      if (
                        Object.keys(workspace.users).includes(currentUserId)
                      ) {
                        return;
                      }
                      setModalWorkspace(workspace);
                      event.preventDefault();
                    }}
                    href={workspace['@id']}
                  >
                    {workspace['title']}
                  </UniversalLink>
                </Table.Cell>
                {/* TODO: Remove the hard-coded role strings here and map it to the column */}
                <Table.Cell>
                  <WorkspaceUsersTableCell
                    users={usersByRole['View Workspace']}
                    workspace={workspace}
                    onClick={() => {
                      setModalWorkspace(workspace);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <WorkspaceUsersTableCell
                    users={usersByRole['Edit Workspace']}
                    workspace={workspace}
                    onClick={() => {
                      setModalWorkspace(workspace);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <WorkspaceUsersTableCell
                    users={usersByRole['Share Workspace']}
                    workspace={workspace}
                    onClick={() => {
                      setModalWorkspace(workspace);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {/* TODO: Current page should have `aria-current="page" */}
      <Pagination
        current={currentPage}
        total={data?.items_total / pageSize}
        onChangePage={(event, { value }) => {
          updatePage(value);
        }}
      />
    </>
  );
}

export default WorkspaceManagement;
