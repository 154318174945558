/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import requestOTP from './Auth/requestOTP';
import userSession from './Auth/userSession';
import shares from './Sharing/shares';
import getSharingLink from './Sharing/getSharingLink';
import createWorkspace from './Workspace/Workspace';
import deleteWorkspace from './Workspace/Workspace';
import userInfo from './users/getUserInfo';
import searchUsers from './users/searchUsers';
import getMimeType from './Mime/MimeType';
import siteInfo from './siteInfo/siteInfo';
import { getWorkspaces, addOwnerForWorkspace } from './admin/admin';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  requestOTP,
  userSession,
  shares,
  getSharingLink,
  getWorkspaces,
  addOwnerForWorkspace,
  createWorkspace,
  deleteWorkspace,
  userInfo,
  searchUsers,
  getMimeType,
  siteInfo,
};

export default reducers;
