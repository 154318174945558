/**
 * Content reducer.
 * @module reducers/content/content
 */

import { map, mapKeys, omit } from 'lodash';

import { flattenToAppURL } from '@plone/volto/helpers';

import {
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  GET_WORKSPACES,
} from '~/constants/ActionTypes';

const initialState = {
  create: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  content: null,
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Content reducer.
 * @function content
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function content(state = initialState, action = {}) {
  let { result } = action;
  switch (action.type) {
    case `${CREATE_WORKSPACE}_PENDING`:
    case `${DELETE_WORKSPACE}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${CREATE_WORKSPACE}_SUCCESS`:
      if (result['@static_behaviors']) {
        map(result['@static_behaviors'], (behavior) => {
          result = {
            ...omit(result, behavior),
            ...mapKeys(result[behavior], (value, key) => `${behavior}.${key}`),
          };
        });
      }
      const content = {
        ...result,
        items:
          action.result &&
          action.result.items &&
          action.result.items.map((item) => ({
            ...item,
            url: flattenToAppURL(item['@id']),
          })),
      };
      return {
        ...state,
        content,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${DELETE_WORKSPACE}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
          sort: {
            on: action.sort?.on,
            order: action.sort?.order,
          },
          index: action.index,
        },
      };
    case `${CREATE_WORKSPACE}_FAIL`:
    case `${DELETE_WORKSPACE}_FAIL`:
      return {
        ...state,
        content: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

