import { GET_MIMETYPE } from '../../constants/ActionTypes';

export function getMimeType(contentType) {
  return {
    type: GET_MIMETYPE,
    request: {
      op: 'post',
      path: `/@mime`,
      data: {
        mimeType: contentType,
      },
    },
  };
}
