import { POST_SITEINFO, GET_SITEINFO } from '@root/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

const initialState = {
  get: {
    loading: false,
    loaded: false,
    error: null,
  },
  post: {
    loading: false,
    loaded: false,
    error: null,
  },
  result: {},
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
 function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

export default function siteInfo(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_SITEINFO}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${GET_SITEINFO}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        [getRequestKey(action.type)]: {
          error: null,
          loaded: true,
          loading: false,
        },
      };
    case `${GET_SITEINFO}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: action.error,
          loaded: true,
          loading: false,
        },
      };
    case `${POST_SITEINFO}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loaded: false,
          loading: true,
        },
      };
    case `${POST_SITEINFO}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loaded: true,
          loading: false,
        },
        result: action.result,
      };
    case `${POST_SITEINFO}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: action.error,
          loaded: false,
          loading: false,
        },
      };
    default:
      return state;
  }
}
