import { flattenToAppURL } from '@plone/volto/helpers';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Image, Card, Icon } from 'semantic-ui-react';

const WorkspaceDashboard = ({ items, isEditMode }) => {
  return (
    <>
      <div>
        <Card.Group>
          {items.map((item, index) => (
            <Card
              key={item['@id']}
              href={isEditMode ? '#' : flattenToAppURL(item['@id'])}
            >
              <Image
                src={flattenToAppURL(item['@id'] + '/@@download/avatar')}
              />
              <Card.Content>
                <Card.Header>{item.title ? item.title : item.id}</Card.Header>
                <Card.Meta>
                  by {item.Creator}
                  <br />
                  {item['ModificationDate'] === item['CreationDate']
                    ? ' created '
                    : ' modified '}
                  {moment(item.ModificationDate).fromNow()}
                </Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Icon name="comments" />
                {item.pretashare_total_comments} comments
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </div>
    </>
  );
};

WorkspaceDashboard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default WorkspaceDashboard;
