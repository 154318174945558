/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import { Container, Segment, Image} from 'semantic-ui-react';
import LogoImage from '../../../../../../public/logoInverted.svg';
import packageJson from '../../../../../../package.json';


/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = () => {
  return (
    <Container id="vm-footer" style={{width: '100% !important', margin: "1em 0 0 0 !important"}}>
      <Segment inverted style={{height: '80px', padding: "0.5em !important"}}  textAlign='center'>
        <div style={{margin: "auto", display: "table"}}>
          <p style={{marginBottom: 0, fontSize: "80%"}}><nobr>powered by&nbsp;<a href="https://www.pretagov.com.au/products/vaultmatix" target="_blank"><Image src={LogoImage} style={{display: "inline"}} height="40px"/></a></nobr></p>

          <p style={{margin: 0, fontSize: "75%"}}>version {packageJson.version}</p>
        </div>

      </Segment>
    </Container>

  )
};

export default Footer;
