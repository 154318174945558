import { getSharing, updateSharing } from '@plone/volto/actions';
import { Icon, SidebarPortal, VideoSidebar } from '@plone/volto/components';
import videoBlockSVG from '@plone/volto/components/manage/Blocks/Video/block-video.svg';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import {
  flattenToAppURL,
  getBaseUrl,
  getParentUrl,
  isInternalURL,
} from '@plone/volto/helpers';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Button, Embed, Input, Message } from 'semantic-ui-react';

const messages = defineMessages({
  VideoFormDescription: {
    id: 'Specify a youtube video or playlist url',
    defaultMessage: 'Specify a youtube video or playlist url',
  },
  VideoBlockInputPlaceholder: {
    id: 'Type a Video (YouTube, Vimeo or mp4) URL',
    defaultMessage: 'Type a Video (YouTube, Vimeo or mp4) URL',
  },
});

class Edit extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onSubmitUrl = this.onSubmitUrl.bind(this);
    this.onKeyDownVariantMenuForm = this.onKeyDownVariantMenuForm.bind(this);
    this.state = {
      url: '',
    };
  }

  onChangeUrl(url) {
    this.setState({
      url: url,
    });
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.selected ||
      nextProps.selected ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  onSubmitUrl() {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
    });
    const [, workspaceFolderId, workspaceId] = this.props.pathname.split('/');

    this.props.updateSharing(
      // getBaseUrl(`/${workspaceFolderId}/${workspaceId}`),
      this.state.url,
      {
        entries: [
          {
            id: 'test_group',
            type: 'group',
            roles: {
              Reader: true,
            },
          },
        ],
      },
    );
  }

  resetSubmitUrl = () => {
    this.setState({
      url: '',
    });
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  onKeyDownVariantMenuForm(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { data } = this.props;
    const placeholder =
      this.props.data.placeholder ||
      this.props.intl.formatMessage(messages.VideoBlockInputPlaceholder);

    return (
      <div
        className={cx(
          'block video align',
          {
            selected: this.props.selected,
            center: !Boolean(this.props.data.align),
          },
          this.props.data.align,
        )}
      >
        {data.url ? (
          <div
            className={cx('video-inner', {
              'full-width': this.props.data.align === 'full',
            })}
          >
            {data.url.match('youtu') ? (
              <>
                {data.url.match('list') ? (
                  data.preview_image ? (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      placeholder={
                        isInternalURL(data.preview_image)
                          ? `${flattenToAppURL(
                              data.preview_image,
                            )}/@@images/image`
                          : data.preview_image
                      }
                      defaultActive
                      autoplay={false}
                    />
                  ) : (
                    <Embed
                      url={`https://www.youtube.com/embed/videoseries?list=${
                        data.url.match(/^.*\?list=(.*)$/)[1]
                      }`}
                      icon="play"
                      defaultActive
                      autoplay={false}
                    />
                  )
                ) : data.preview_image ? (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={
                      data.url.match(/.be\//)
                        ? data.url.match(/^.*\.be\/(.*)/)[1]
                        : data.url.match(/^.*\?v=(.*)$/)[1]
                    }
                    source="youtube"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )}
              </>
            ) : (
              <>
                <div className="ui blocker" />
                {data.url.match('vimeo') ? (
                  data.preview_image ? (
                    <Embed
                      id={data.url.match(/^.*\.com\/(.*)/)[1]}
                      source="vimeo"
                      placeholder={
                        isInternalURL(data.preview_image)
                          ? `${flattenToAppURL(
                              data.preview_image,
                            )}/@@images/image`
                          : data.preview_image
                      }
                      icon="play"
                      autoplay={false}
                    />
                  ) : (
                    <Embed
                      id={data.url.match(/^.*\.com\/(.*)/)[1]}
                      source="vimeo"
                      icon="play"
                      defaultActive
                      autoplay={false}
                    />
                  )
                ) : (
                  <>
                    <div className="ui blocker" />
                    {data.url.match('.mp4') || isInternalURL(data.url) ? (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video
                        src={
                          isInternalURL(
                            data.url.replace(
                              getParentUrl(config.settings.apiPath),
                              '',
                            ),
                          )
                            ? `${data.url}/@@download/file`
                            : data.url
                        }
                        controls
                        poster={
                          data.preview_image
                            ? isInternalURL(data.preview_image)
                              ? `${flattenToAppURL(
                                  data.preview_image,
                                )}/@@images/image`
                              : data.preview_image
                            : ''
                        }
                        type="video/mp4"
                      />
                    ) : (
                      <div>
                        <Message>
                          <center>
                            <FormattedMessage
                              id="Please enter a valid URL by deleting the block and adding a new video block."
                              defaultMessage="Please enter a valid URL by deleting the block and adding a new video block."
                            />
                          </center>
                        </Message>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ) : (
          <Message>
            <center>
              <img src={videoBlockSVG} alt="" />
              <div className="toolbar-inner">
                <Button.Group>
                  <Button
                    basic
                    icon
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.openObjectBrowser({
                        mode: 'link',
                        onSelectItem: (url, item) => {
                          this.onChangeUrl(`${flattenToAppURL(url)}`);
                        },
                      });
                    }}
                  >
                    <Icon name={navTreeSVG} size="24px" />
                  </Button>
                </Button.Group>
                <Input
                  onKeyDown={this.onKeyDownVariantMenuForm}
                  onChange={(event) => {
                    this.onChangeUrl(event.target.value);
                  }}
                  placeholder={placeholder}
                  value={this.state.url}
                  // Prevents propagation to the Dropzone and the opening
                  // of the upload browser dialog
                  onClick={(e) => e.stopPropagation()}
                />
                {this.state.url && (
                  <Button.Group>
                    <Button
                      basic
                      className="cancel"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ url: '' });
                      }}
                    >
                      <Icon name={clearSVG} size="30px" />
                    </Button>
                  </Button.Group>
                )}
                <Button.Group>
                  <Button
                    basic
                    primary
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onSubmitUrl();
                    }}
                  >
                    <Icon name={aheadSVG} size="30px" />
                  </Button>
                </Button.Group>
              </div>
            </center>
          </Message>
        )}
        <SidebarPortal selected={this.props.selected}>
          <VideoSidebar {...this.props} resetSubmitUrl={this.resetSubmitUrl} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withObjectBrowser,
  injectIntl,
  connect(
    (state, props) => ({
      // entries: state.sharing.data.entries,
      // inherit: state.sharing.data.inherit,
      // available_roles: state.sharing.data.available_roles,
      // updateRequest: state.sharing.update,
      pathname: props.location.pathname,
      // title: state.content.data.title,
      // login: state.userSession.token
      //   ? jwtDecode(state.userSession.token).sub
      //   : '',
    }),
    { updateSharing, getSharing },
  ),
)(Edit);
// export default injectIntl(withObjectBrowser(Edit));
