import { POST_SHARES, GET_SHARES } from '../../constants/ActionTypes';

export function shares(url, data) {
  return {
    type: POST_SHARES,
    request: {
      op: 'post',
      path: `${url}/@shares`,
      data: {
        email: data?.email || [],
        roles: data?.roles || {},
        send_link: data?.send_link || false,
        customEmail: data?.customEmail || null,
      },
    },
  };
}

export function getSharingLink(url) {
  return {
    type: GET_SHARES,
    request: {
      op: 'get',
      path: `${url}/@shares`,
    },
  };
}

export function sharingLink(url, data) {
  return {
    type: POST_SHARES,
    request: {
      op: 'post',
      path: `${url}/@sharingLink`,
      data: {
        link: data?.link || null,
      },
    },
  };
}
