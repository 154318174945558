import {
  flattenToAppURL,
  getParentUrl,
  isInternalURL,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import cx from 'classnames';
import React from 'react';
import { Embed } from 'semantic-ui-react';

const View = ({ data }) => {
  const url = data.url?.replace('@@download/file', '');

  if (!url) {
    return null;
  }

  return (
    <div
      className={cx(
        'block video align',
        {
          center: !Boolean(data.align),
        },
        data.align,
      )}
    >
      {url && (
        <div
          className={cx('video-inner', {
            'full-width': data.align === 'full',
          })}
        >
          {url.match('youtu') ? (
            <>
              {url.match('list') ? (
                data.preview_image ? (
                  <Embed
                    url={`https://www.youtube.com/embed/videoseries?list=${
                      url.match(/^.*\?list=(.*)$/)[1]
                    }`}
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    defaultActive
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    url={`https://www.youtube.com/embed/videoseries?list=${
                      url.match(/^.*\?list=(.*)$/)[1]
                    }`}
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )
              ) : data.preview_image ? (
                <Embed
                  id={
                    url.match(/.be\//)
                      ? url.match(/^.*\.be\/(.*)/)[1]
                      : url.match(/^.*\?v=(.*)$/)[1]
                  }
                  source="youtube"
                  placeholder={
                    isInternalURL(data.preview_image)
                      ? `${flattenToAppURL(data.preview_image)}/@@images/image`
                      : data.preview_image
                  }
                  icon="play"
                  autoplay={false}
                />
              ) : (
                <Embed
                  id={
                    url.match(/.be\//)
                      ? url.match(/^.*\.be\/(.*)/)[1]
                      : url.match(/^.*\?v=(.*)$/)[1]
                  }
                  source="youtube"
                  icon="play"
                  defaultActive
                  autoplay={false}
                />
              )}
            </>
          ) : (
            <>
              {url.match('vimeo') ? (
                data.preview_image ? (
                  <Embed
                    id={url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    placeholder={
                      isInternalURL(data.preview_image)
                        ? `${flattenToAppURL(
                            data.preview_image,
                          )}/@@images/image`
                        : data.preview_image
                    }
                    icon="play"
                    autoplay={false}
                  />
                ) : (
                  <Embed
                    id={url.match(/^.*\.com\/(.*)/)[1]}
                    source="vimeo"
                    icon="play"
                    defaultActive
                    autoplay={false}
                  />
                )
              ) : (
                <>
                  {url.match('.mp4') ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                      src={
                        isInternalURL(
                          url.replace(
                            getParentUrl(config.settings.apiPath),
                            '',
                          ),
                        )
                          ? `${url}/@@download/file`
                          : url
                      }
                      controls
                      poster={
                        data.preview_image
                          ? isInternalURL(data.preview_image)
                            ? `${flattenToAppURL(
                                data.preview_image,
                              )}/@@images/image`
                            : data.preview_image
                          : ''
                      }
                      type="video/mp4"
                    />
                  ) : (
                    <div className="invalidVideoFormat" />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default View;
