import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Segment } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { sharingLink } from '../../actions';
import {
  getBaseUrl,
  flattenToAppURL,
  asyncConnect,
} from '@plone/volto/helpers';

const Share = () => {
  const { shares } = useSelector((state) => ({
    shares: state.reduxAsyncConnect.shares,
  }));

  // This is the happy path. Everything after here is the error-handling
  if (shares && shares.status && shares.status !== 'Error') {
    return <Redirect to={flattenToAppURL(shares.status)} />;
  }

  return (
    <Container text>
      <Segment.Group raised>
        <Segment className="primary">
          <div>
            <h1>There was a problem logging in</h1>
          </div>
        </Segment>
        <Segment secondary>
          <p>There was an error with the sharing link supplied.</p>
          <p>
            Please try <a href="/login">logging in</a> and navigating to the
            content you are tyring to access.
          </p>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default asyncConnect([
  {
    key: 'shares',
    promise: ({ store: { dispatch }, match }) => {
      const code = match.params.code;
      return dispatch(sharingLink(getBaseUrl(''), { link: code }));
    },
  },
])(Share);
